<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start" fxFlexFill>
    <div id="login-intro" class="login-intro" fxFlex fxHide fxShow.gt-xs>
        <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
            <img id="logo-meuconsultorio" src="assets/images/logos/afiliados_branca.png" [attr.alt]="'ALTS.LOGOTIPO' | translate" />
        </div>

        <div class="title" [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">
            {{ 'LABELS.BEMVINDO' | translate }}
        </div>

        <div class="description" [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }">
            {{ 'LABELS.ENTRECOMSUACONTA' | translate }}
        </div>

        <!-- Rodapé -->
        <div class="container">
            <div
                fxLayout="row"
                fxLayout="100"
                fxLayoutAlign="space-around center"
                class="footerLogin"
                ngClass.sm="footerLoginSm"
                ngClass.md="footerLoginMd"
            >
            <a fxFlex.lt-lg class="btnFooter" target="_blank" href="https://www.amplum.solutions/politica-de-privacidade/">
                {{ 'LABELS.POLITICAPRIVACIDADE' | translate }}
            </a>
                <a fxFlex class="btnFooter text-center" target="_blank" href="https://api.whatsapp.com/send?phone=5537998046516">{{ 'LABELS.AJUDA' | translate }}</a>
                <a fxFlex class="btnFooter text-center" target="_blank" href="https://amplum.solutions">{{ 'LABELS.WMI' | translate }}</a>
            </div>
        </div>

        <div class="logo" style="position: absolute; bottom: 0; left: 0; padding: 0 0 20px 20px; width: 60px;">
            <div class="logo-whatsapp" (click)="this.abrirWhatsapp()">
                <img class="h-40 w-40 pl-5 pb-5 contato-whatsapp logo-icon" src="assets/icons/Whatsapp_original.svg" />
            </div>
        </div>
    </div>
    <div id="login-form-wrapper" fusePerfectScrollbar class="login-form-wrapper" [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
        <div id="login-form">
            <div class="logo" fxHide.gt-xs>
                <img id="logo-meuconsultorio-mobile" src="assets/images/logos/afiliados_128.png" />
            </div>

            <div class="title text-center">
                <b>
                    {{ 'LABELS.FACALOGIN' | translate }}
                </b>
            </div>

            <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="text-center" novalidate>
                <span appearance="outline" *ngIf="!this.loginPorCpf">
                    {{ 'LABELS.CLIQUE' | translate }}
                    <a id="login-clique-aqui" routerLink="." (click)="this.trocarTipoLogin()">{{ 'LABELS.AQUI' | translate }}</a>
                    {{ 'LABELS.ALTERARTIPOLOGIN' | translate }} CPF.
                </span>

                <span appearance="outline" *ngIf="this.loginPorCpf">
                    {{ 'LABELS.CLIQUE' | translate }}
                    <a id="login-clique-aqui" routerLink="." (click)="this.trocarTipoLogin()">{{ 'LABELS.AQUI' | translate }}</a>
                    {{ 'LABELS.ALTERARTIPOLOGIN' | translate }} e-mail.
                </span>
                <br /><br />

                <mat-form-field id="login-email-form-field" appearance="outline" *ngIf="!this.loginPorCpf">
                    <mat-label>{{ 'LABELS.EMAIL' | translate }}</mat-label>
                    <input id="login-email" matInput formControlName="email" [required]="!this.loginPorCpf" />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error id="login-erro-email-nao-informado" *ngIf="loginForm.get('email').hasError('required')">
                        {{ 'MENSAGENS.EMAILNAOINFORMADO' | translate }}
                    </mat-error>
                    <mat-error
                        id="login-erro-email-invalido"
                        *ngIf="!loginForm.get('email').hasError('required') && loginForm.get('email').hasError('email')"
                    >
                        {{ 'MENSAGENS.EMAILINVALIDO' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field id="login-cpf-form-field" appearance="outline" *ngIf="this.loginPorCpf">
                    <mat-label>{{ 'CPF' | translate }}</mat-label>
                    <input id="login-cpf" matInput formControlName="cpf" [required]="this.loginPorCpf" mask="000.000.000-00" [showMaskTyped]="true" />
                    <mat-icon matSuffix class="secondary-text">https</mat-icon>
                    <mat-error id="login-erro-cpf-nao-informado" *ngIf="loginForm.get('cpf').hasError('required')">
                        {{ 'MENSAGENS.CPFNAOINFORMADO' | translate }}
                    </mat-error>
                    <mat-error
                        id="login-erro-cpf-invalido"
                        *ngIf="!loginForm.get('cpf').hasError('required') && loginForm.get('cpf').hasError('cpfInvalido')"
                    >
                        {{ 'MENSAGENS.CPFINVALIDO' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'LABELS.SENHA' | translate }}</mat-label>
                    <input id="login-senha" matInput type="password" formControlName="senha" required />
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error id="login-erro-senha-obrigatoria">
                        {{ 'MENSAGENS.SENHAOBRIGATORIA' | translate }}
                    </mat-error>
                </mat-form-field>

                <div class="remember-esqueceu-senha" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <mat-checkbox
                        id="login-lembrar-senha"
                        color="primary"
                        class="remember-me"
                        aria-label="Lembrar senha"
                        [(ngModel)]="this.lembrarSenha"
                        [ngModelOptions]="{ standalone: true }"
                    >
                        {{ 'LABELS.LEMBRARSENHA' | translate }}
                    </mat-checkbox>

                    <a id="login-esqueceu-senha" class="esqueceu-senha" [routerLink]="'/esqueceu-senha'">
                        {{ 'LABELS.ESQUECEUSENHA' | translate }}
                    </a>
                </div>

                <button
                    id="login-botao-entrar"
                    mat-raised-button
                    color="primary"
                    class="submit-button"
                    aria-label="ENTRAR"
                    [disabled]="loginForm.invalid || this.fuseProgressBarService.visibleValue"
                >
                    {{ 'LABELS.ENTRAR' | translate }}
                </button>
            </form>

            <div class="separator">
                <span class="text">{{ 'LABELS.OU' | translate }}</span>
            </div>

            <div fxLayout="column" fxLayoutAlign="start center">
                <button id="login-google" mat-raised-button class="google" (click)="this.loginComGoogle()">
                    <img src="assets/icons/google.svg" alt="Cadastro com Google" class="iconeButton mb-4" />
                    {{ 'LABELS.ENTARCOMGOOGLE' | translate }}
                </button>

                <button id="login-facebook" mat-raised-button class="facebook" (click)="this.loginComFacebook()">
                    <img src="assets/icons/facebook-app.svg" alt="Cadastro com Facebook" class="iconeButton mb-4" />
                    {{ 'LABELS.ENTRARCOMFACEBOOK' | translate }}
                </button>
            </div>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">{{ 'LABELS.NAOTEMCONTA' | translate }}</span>
                <a id="login-link-registrar" class="link" [routerLink]="'/registrar'">{{ 'LABELS.CADASTREAQUI' | translate }}</a>
            </div>

            <!-- Rodapé -->
            <div class="container" fxHide.gt-xs>
                <div fxLayout="row" fxLayout="100" fxLayoutAlign="space-around center" class="pr-16">
                    <a fxFlex.lt-lg class="btnFooter" target="_blank" href="https://www.amplum.solutions/politica-de-privacidade/">
                        {{ 'LABELS.POLITICAPRIVACIDADE' | translate }}
                    </a>
                    <a fxFlex class="btnFooterMobile text-center" target="_blank" href="https://amplum.solutions">
                        {{ 'LABELS.AJUDA' | translate }}
                    </a>
                    <a fxFlex class="btnFooterMobile text-center" target="_blank" href="https://amplum.solutions">
                        {{ 'LABELS.WMI' | translate }}
                    </a>
                </div>
            </div>

            <div style="position: absolute; bottom: 0; left: 0; padding: 0 0 12px 12px" fxHide.gt-xs fxLayoutAlign="start center">
                <div class="logo-whatsapp" (click)="this.abrirWhatsapp()">
                    <img class="h-40 w-40 contato-whatsapp logo-icon" src="assets/icons/Whatsapp_original.svg" />
                </div>
            </div>
        </div>
    </div>
</div>
