<div id="esqueceu-senha" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
    <div id="esqueceu-senha-intro" fxFlex fxHide fxShow.gt-xs>
        <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
            <img src="assets/images/logos/afiliados_branca.png" id="logo-meuconsultorio" [attr.alt]="'LOGIN.ALTS.LOGOTIPO' | translate" />
        </div>

        <div class="title" [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">
            {{ 'LABELS.DIFICULDADEACESSO' | translate }}
        </div>

        <div class="description" [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }">
            {{ 'LABELS.NAO_SE_PREOCUPE' | translate }}
        </div>
        <!-- Rodapé -->
        <div class="container">
            <div fxLayout="row" fxLayout="100" fxLayoutAlign="space-around center" class="footerEsqueceu">
                <a fxFlex class="btnFooter" target="_blank" href="https://www.amplum.solutions/politica-de-privacidade/">{{
                'LABELS.POLITICAPRIVACIDADE' | translate}}</a>
                <a fxFlex class="btnFooter text-center" target="_blank" href="https://amplum.solutions">{{ 'LABELS.AJUDA' | translate }}</a>
                <a fxFlex class="btnFooter text-center" target="_blank" href="https://amplum.solutions">{{ 'LABELS.WMI' | translate }}</a>
            </div>
        </div>
    </div>

    <div id="esqueceu-senha-form-wrapper" fusePerfectScrollbar [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
        <div id="esqueceu-senha-form" *ngIf="!this.sucessoRecuperacaoSenha">
            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/afiliados_128.png" id="logo-meuconsultorio" [attr.alt]="'LOGIN.ALTS.LOGOTIPO' | translate" />
            </div>

            <div class="title">{{ 'LABELS.RECUPERESUASENHA' | translate }}</div>

            <form name="forgoPasswordForm" [formGroup]="forgotPasswordForm" (ngSubmit)="this.onSubmit()" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'LABELS.EMAIL' | translate }}</mat-label>
                    <input matInput formControlName="email" />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        {{ 'MENSAGENS.EMAILNAOINFORMADO' | translate }}
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        {{ 'MENSAGENS.EMAILINVALIDO' | translate }}
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email-existente')">
                        {{ 'MENSAGENS.EMAILINEXISTENTE' | translate }}
                    </mat-error>
                </mat-form-field>

                <button
                    mat-raised-button
                    class="submit-button"
                    color="primary"
                    [attr.aria-label]="'LABELS.RESETARSENHA' | translate"
                    [disabled]="forgotPasswordForm.invalid"
                >
                    {{ 'LABELS.RESETARSENHA' | translate }}
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">{{ 'LABELS.VOLTARPARAOLOGIN' | translate }}</a>
            </div>
        </div>

        <div id="esqueceu-senha-form" *ngIf="this.sucessoRecuperacaoSenha">
            <div class="title">Solicitação enviada com sucesso!</div>
            <p>Você recebeu as instruções para recuperação de acesso a sua conta no email {{ this.forgotPasswordForm.get('email').value }}.</p>
            <p>Lembre-se de incluir os emails do Amplum em sua lista de remetentes confiáveis para receber todas as nossas notificações.</p>
            <p>
                Caso tenha algum problema com o recebimento deste email,
                <a href="https://app.contako.com.br/Atendimento/?cadastro=52CA58D04F&clinica=true">entre em contato com a gente.</a>
            </p>
        </div>

        <div class="register mb-56" fxLayout="column" fxLayoutAlign="center center">
            <span class="text">{{ 'LABELS.NAOTEMCONTA' | translate }}</span>
            <a class="link" id="link-register" [routerLink]="'/registrar'">{{ 'LABELS.CADASTREAQUI' | translate }}</a>
        </div>
        <!-- Rodapé -->
        <div class="container" fxHide.gt-xs>
            <div fxLayout="row" fxLayout="100" fxLayoutAlign="space-around center" class="footerEsqueceuMobile">
                <a fxFlex class="btnFooter" target="_blank" href="https://www.amplum.solutions/politica-de-privacidade/">{{
                'LABELS.POLITICAPRIVACIDADE' | translate}}</a>
                <a fxFlex class="btnFooterMobile text-center" target="_blank" href="https://amplum.solutions">{{ 'LABELS.AJUDA' | translate }}</a>
                <a fxFlex class="btnFooterMobile text-center" target="_blank" href="https://amplum.solutions">{{
                    'LABELS.WMI' | translate
                }}</a>
            </div>
        </div>
    </div>
</div>
