import { Component, OnInit } from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ModoSalvar } from 'app/_models';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoComponenteAcao } from 'app/_models/enums/tipo-componente-acao';
import { DadosModalAlterarIncluirMaterialApoio } from 'app/_models/material-apoio/dados-modal-alterar-incluir-material-apoio';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { MaterialApoioAfiliados } from 'app/_models/material-apoio/material-apoio-afiliados';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';
import { AuthenticationService, DialogService, ToastService, TraducaoService } from 'app/_services';
import { MaterialApoioAfiliadosControllerService } from 'app/_services/controllers/material-apoio-afiliados-controller.service';
import { ListaAcoes, ListaColunas } from '../componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { AlterarIncluirMaterialApoioComponent } from './alterar-incluir-material-apoio/alterar-incluir-material-apoio.component';

@Component({
    selector: 'app-material-apoio',
    templateUrl: './material-apoio.component.html',
    styleUrls: ['./material-apoio.component.scss'],
})
export class MaterialApoioComponent implements OnInit {
    public listagemMaterial: Array<MaterialApoioAfiliados>;
    public colunas: Array<ListaColunas>;
    public acoes: Array<ListaAcoes>;
    public mostrarBtnInserir: boolean = false;

    constructor(
        private _materialApoioAfiliadosControllerService: MaterialApoioAfiliadosControllerService,
        private fuseProgressBarService: FuseProgressBarService,
        private _i18n: TraducaoService,
        private _toastService: ToastService,
        private _authenticationService: AuthenticationService,
        private _dialogService: DialogService
    ) {}

    ngOnInit() {
        this._colunasBase();
        this.listarPagamentos();
        this._setAcoes();
    }

    public listarPagamentos(): void {
        this.fuseProgressBarService.show();
        this._materialApoioAfiliadosControllerService.listaMaterialApoio().subscribe(
            (res) => {
                if (res.sucesso) {
                    this.listagemMaterial = res.materialApoioAfiliados;
                    this.fuseProgressBarService.hide();
                } else {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                console.log(err);
            }
        );
    }

    //Quando o TamanhosFlexLayout é diferente de xs, não exibe colunas para dispositivos com largura menor que 600
    private _colunasBase(): void {
        this.colunas = new Array<ListaColunas>(
            {
                descricao: this._i18n.get('LABELS.DESCRICAO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'descricao',
                tipoDado: TipoDado.TEXTO,
                width: 'w-80-p',
            },
            {
                descricao: this._i18n.get('LABELS.DATA_DE_CADASTRO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'dataCadastro',
                tipoDado: TipoDado.DATA,
                width: 'w-15-p',
            }
        );
    }

    private _setAcoes(): void {
        if (this.perfil == RoleUsuario.ADMINISTRADOR_AFILIADO) {
            this.acoes = [
                {
                    decricaoAlt: 'Editar',
                    codigo: 'EDITAR',
                    icon: 'edit',
                    tipoComponenteAcao: TipoComponenteAcao.BUTTON,
                },
            ];
        } else {
            this.acoes = [
                {
                    decricaoAlt: 'Baixar',
                    codigo: 'BAIXAR',
                    icon: 'arrow_downward',
                    tipoComponenteAcao: TipoComponenteAcao.BUTTON,
                },
            ];
        }
    }

    public getAcoesLinha(): Array<string> {
        const acoes: Array<string> = new Array();
        acoes.push('EDITAR');
        acoes.push('BAIXAR');
        return acoes;
    }

    public acao(dados: any): void {
        if (this.perfil == RoleUsuario.ADMINISTRADOR_AFILIADO) {
            this.modalAlerar(dados.dados);
        } else {
            window.open(dados.dados.caminhoAnexo);
        }
    }

    private get perfil(): RoleUsuario {
        switch (this._authenticationService.currentUserValue.perfil) {
            case RoleUsuario.ADMINISTRADOR_AFILIADO:
                this.mostrarBtnInserir = true;
                return RoleUsuario.ADMINISTRADOR_AFILIADO;
            case RoleUsuario.AFILIADO:
                return RoleUsuario.AFILIADO;
        }
    }

    public modalIncluir(): void {
        const data: DadosModalAlterarIncluirMaterialApoio = {
            modoSalvar: ModoSalvar.Post,
        };
        this._dialogService
            .dialogGenerico(AlterarIncluirMaterialApoioComponent, {
                autoFocus: false,
                data,
                disableClose: false,
                height: '90%',
            })
            .subscribe((res: boolean) => {
                if (res) {
                    this.listarPagamentos();
                }
            });
    }

    public modalAlerar(dados: MaterialApoioAfiliados): void {
        const data: DadosModalAlterarIncluirMaterialApoio = {
            modoSalvar: ModoSalvar.Put,
            caminhoAnexo: dados.caminhoAnexo,
            id: dados.id,
            descricao: dados.descricao,
            tipoAnexo: dados.tipoAnexo,
        };
        this._dialogService
            .dialogGenerico(AlterarIncluirMaterialApoioComponent, {
                autoFocus: false,
                data,
                disableClose: false,
                height: '90%',
            })
            .subscribe((res: boolean) => {
                if (res) {
                    this.listarPagamentos();
                }
            });
    }
}
