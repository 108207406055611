import { Component, OnInit } from '@angular/core';
import { ContaProfissionalAfiliadoControllerService } from 'app/_services/controllers/conta-profissional-afiliado-controller.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ExportarPdfService } from 'app/_services/exportacao/exportar-pdf.service';
import { TraducaoService } from 'app/_services/traducao.service';
import { ToastService } from 'app/_services/toast.service';
import { ListagemPagamentosDto } from 'app/_models/conta-profissional-afiliado/listagem-pagamentos-dto';
import { DadosPagamentoDto } from 'app/_models/conta-profissional-afiliado/dados-pagamento-dto';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePipe, formatCurrency } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'app/_constants';
import { format } from 'date-fns';
import { FiltroListarPagamentosDto } from '../../_models/conta-profissional-afiliado/filtro-listar-pagamentos-dto';
import { ListaColunas, ListaAcoes } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { SubMenu } from 'app/_models/configuracoes/financeiro/submenus';
import { DadosParaExportacaoBasicoDto } from 'app/_models/exportacao/dados-para-exportacao-basico-dto';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { TipoComponenteAcao } from 'app/_models/enums/tipo-componente-acao';
import { EventoListagemPagamento } from 'app/_models/conta-profissional-afiliado/evento-listagem-pagamento';
import { SelectAllModel } from 'app/_models/global/select-all-model';
import { IdDescricaoDto } from 'app/_models/global/id-descricao-dto';
import { SituacaoBonusAfiliado } from '../../_models/_enums/situacao-bonus-afiliado.enum';
import { FuseUtils } from '@fuse/utils';
import { ColunaExportacao } from 'app/_models/exportacao/coluna-exportacao';
import { Dictionary } from 'lodash';
import { AlterarPagamentoComponent } from 'app/main/pagamentos/alterar-pagamento/alterar-pagamento.component';
import { DialogService } from 'app/_services/dialogs/dialog.service';
import { ExportaXLSXService } from 'app/_services/exportacao/exporta-xlsx.service';

@Component({
    selector: 'pagamentos',
    templateUrl: './pagamentos.component.html',
    styleUrls: ['./pagamentos.component.scss'],
    providers: [
        DatePipe,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class PagamentosComponent implements OnInit {
    public listagemPagamentos: Array<ListagemPagamentosDto>;
    public listagemPagamentosFiltrado: Array<ListagemPagamentosDto>;
    public dadosPagamento: DadosPagamentoDto;
    public colunas: Array<ListaColunas>;
    public acoes: Array<ListaAcoes>;
    public subMenus: Array<SubMenu>;
    public dadosParaExportacaoBasicoDto: DadosParaExportacaoBasicoDto;
    public filtroListagemPagamento: FiltroListarPagamentosDto;
    public situacoesFiltro: Array<IdDescricaoDto>;
    private textoFiltro: string;

    constructor(
        private _contaProfissionalAfiliadoControllerService: ContaProfissionalAfiliadoControllerService,
        private fuseProgressBarService: FuseProgressBarService,
        private _i18n: TraducaoService,
        private _toastService: ToastService,
        private _exportarPdfService: ExportarPdfService,
        private _dialogService: DialogService,
        private _exportaXLSXService: ExportaXLSXService,
        private datePipe: DatePipe
    ) {
        this.situacoesFiltro = new Array<IdDescricaoDto>();
        this.listagemPagamentosFiltrado = new Array<ListagemPagamentosDto>();
        this.listagemPagamentos = new Array<ListagemPagamentosDto>();
        this.colunas = new Array<ListaColunas>();
        this.acoes = new Array<ListaAcoes>();
    }

    ngOnInit(): void {
        this.setAcoes();
        this.setColunas();
        this.processarListagemPagamentos();
    }

    public listarPagamentos(): void {
        this.fuseProgressBarService.show();

        this._contaProfissionalAfiliadoControllerService.listarPagamentos(this.filtroListagemPagamento).subscribe(
            (res) => {
                if (res.sucesso) {
                    res.listagemPagamentosDTO.forEach((item) => {
                        item.afiliadoNome = this._primeiraLetraUppCase(item.afiliadoNome);
                    });

                    this.listagemPagamentos = res.listagemPagamentosDTO;

                    this.listagemPagamentosFiltrado = this.listagemPagamentos;

                    if (!!this.textoFiltro) {
                        this.setDadosFiltrados(this.textoFiltro);
                    }

                    this.fuseProgressBarService.hide();
                } else {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                console.log(err);
            }
        );
    }

    private processarListagemPagamentos(): void {
        this.setFiltroSituacoes();
        this.filtroListagemPagamento = new FiltroListarPagamentosDto(this.situacoesFiltro.map((situacao) => situacao.id));
        this.listarPagamentos();
    }

    private setFiltroSituacoes(): void {
        this.situacoesFiltro = new Array<IdDescricaoDto>(
            {
                id: SituacaoBonusAfiliado.RESGATADO,
                descricao: this._i18n.get('LABELS.PAGO'),
            },
            {
                id: SituacaoBonusAfiliado.EM_ANALISE,
                descricao: this._i18n.get('LABELS.PENDENTE'),
            }
        );
    }

    public atualizarDataInicio(data: Date): void {
        this.filtroListagemPagamento.dataInicio = format(data, 'YYYY-MM-DD');
        this.listarPagamentos();
    }

    public atualizarDataFim(data: Date): void {
        this.filtroListagemPagamento.dataFim = format(data, 'YYYY-MM-DD');
        this.listarPagamentos();
    }

    private setColunas(): void {
        this.colunas = new Array<ListaColunas>(
            {
                descricao: this._i18n.get('LABELS.AFILIADO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'afiliadoNome',
                maxLengthKey: 17,
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.DATA_PEDIDO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'dataPedido',
                tipoDado: TipoDado.DATA,
            },
            {
                descricao: this._i18n.get('LABELS.VALOR_TOTAL'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'valorTotal',
                tipoDado: TipoDado.MONETARIO,
                tooltipKey: 'nome',
            },
            {
                descricao: this._i18n.get('LABELS.SITUACAO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'situacaoPagamento',
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.DATA_PAGAMENTO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'dataPagamento',
                tipoDado: TipoDado.DATA,
            },
            {
                descricao: this._i18n.get('LABELS.FORMA_PAGAMENTO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'formaPagamentoDescricao',
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.NOTA_FISCAL'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'notaFiscal',
                tipoDado: TipoDado.TEXTO,
            }
        );
    }

    public alterarPagamento(dadosPagamento: EventoListagemPagamento): void {
        if (!dadosPagamento.dados.podeAlterar) {
            this._dialogService.dialogInformacao(
                `${this._i18n.get('LABELS.ATENCAO')}`,
                `${this._i18n.get('MENSAGENS.PAGAMENTO_NAO_PODE_SER_ALTERADO')}`
            );
            return;
        }

        this.fuseProgressBarService.show();

        this._contaProfissionalAfiliadoControllerService.selecionarPagamento(dadosPagamento.dados.contaProfissionalAfiliadoId).subscribe(
            (res) => {
                if (res.sucesso) {
                    res.dadosPagamentoDTO.afiliadoId = dadosPagamento.dados.afiliadoId;

                    this._dialogService
                        .dialogGenerico(AlterarPagamentoComponent, {
                            autoFocus: false,
                            data: res.dadosPagamentoDTO,
                            disableClose: false,
                        })
                        .subscribe((alterado: boolean) => {
                            if (alterado) {
                                this.listarPagamentos();
                            }
                        });

                    this.fuseProgressBarService.hide();
                } else {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                console.log(err);
            }
        );
    }

    private setAcoes(): void {
        this.acoes = [
            {
                decricaoAlt: 'Editar',
                codigo: 'EDITAR',
                icon: 'edit',
                tipoComponenteAcao: TipoComponenteAcao.BUTTON,
            },
        ];
    }

    public getAcoesLinha(): Array<string> {
        const acoes: Array<string> = new Array();
        acoes.push('EDITAR');
        return acoes;
    }

    public onSelectItemSituacao(selectedList: Array<SelectAllModel>): void {
        if (!selectedList.length) {
            return;
        }

        this.filtroListagemPagamento.situacoesPagamento = selectedList.map((situacao) => situacao.id);

        this.listarPagamentos();
    }

    public exportarParaPdf(): void {
        const dadosExportacao = this.getDadosParaExportacaoBasicoDto();
        this._exportarPdfService.gerarPdfAfiliados(dadosExportacao);
    }

    private getDadosParaExportacaoBasicoDto(): DadosParaExportacaoBasicoDto {
        const colunasParaExportacao = [
            new ColunaExportacao('afiliadoNome', `${this._i18n.get('LABELS.AFILIADO')}`, 1, 100),
            new ColunaExportacao('dataPedido', `${this._i18n.get('LABELS.DATA_PEDIDO')}`, 1, 60),
            new ColunaExportacao('valorTotal', `${this._i18n.get('LABELS.VALOR_TOTAL')}`, 2, 60),
            new ColunaExportacao('situacaoPagamento', `${this._i18n.get('LABELS.SITUACAO')}`, 3, 60),
            new ColunaExportacao('dataPagamento', `${this._i18n.get('LABELS.DATA_PAGAMENTO')}`, 3, 60),
            new ColunaExportacao('formaPagamento', `${this._i18n.get('LABELS.FORMA_PAGAMENTO')}`, 3, 75),
            new ColunaExportacao('notaFiscal', `${this._i18n.get('LABELS.NOTA_FISCAL')}`, 3, 75),
        ];

        const dadosParaExportacaoBasicoDto = new DadosParaExportacaoBasicoDto(
            colunasParaExportacao,
            this.mapearDadosPdf(),
            this._i18n.get('TITULOS.PAGAMENTOS')
        );

        return dadosParaExportacaoBasicoDto;
    }

    public formatarData(data: Date | string): string {
        return this.datePipe.transform(data, 'dd/MM/yyyy');
    }

    private mapearDadosPdf(): Array<Dictionary<string>> {
        const retorno = new Array<Dictionary<string>>();

        this.listagemPagamentosFiltrado.forEach((item) => {
            const dict = {
                afiliadoNome: item.afiliadoNome,
                dataPedido: this.formatarData(item.dataPedido),
                valorTotal: formatCurrency(item.valorTotal as number, 'pt-BR', 'R$'),
                situacaoPagamento: item.situacaoPagamento,
                dataPagamento: this.formatarData(item.dataPagamento),
                formaPagamento: item.formaPagamentoDescricao,
                notaFiscal: item.notaFiscal,
            };

            retorno.push(dict);
        });

        return retorno;
    }

    public setDadosFiltrados(query: string): void {
        this.textoFiltro = query;
        const filtro = FuseUtils.filterArrayByString(this.listagemPagamentos, query);
        this.listagemPagamentosFiltrado = filtro;
    }

    public exportarParaXLSX(): void {
        const retorno = new Array<Dictionary<string>>();

        this.listagemPagamentosFiltrado.forEach((pg) => {
            const dict = {
                'Afiliado': pg.afiliadoNome,
                'Data pedido': this.formatarData(pg.dataPedido),
                'Valor total': pg.valorTotal as string,
                'Situacao': pg.situacaoPagamento,
                'Data do pagamento': this.formatarData(pg.dataPagamento),
                'Forma do pagamento': pg.formaPagamentoDescricao as string,
                'Nota fiscal': pg.notaFiscal as string,
            };
            retorno.push(dict);
        });
        this._exportaXLSXService.exportAsExcelFile(retorno, 'pagamentos');
    }
    private _primeiraLetraUppCase(texto: string): string {
        var words = texto.toLowerCase().split(' ');
        for (var a = 0; a < words.length; a++) {
            var w = words[a];
            if (w.length > 0) {
                words[a] = w[0].toUpperCase() + w.slice(1);
            }
        }
        return words.join(' ');
    }
}
