import { FuseTranslationLoaderService } from './../../../@fuse/services/translation-loader.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/_services/authentication.service';
import { locale as portugues } from 'app/_i18n/pt-br';
import { User } from 'app/_models';
import { AlterarPerfilComponent } from './alterar-perfil/alterar-perfil/alterar-perfil.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha/alterar-senha.component';
import { DialogService, ToastService, TraducaoService } from 'app/_services';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';
import { ContaColaboradorControllerService } from 'app/_services/controllers/conta-colaborador-controller.service';
import { ContaProfissionalControllerService } from 'app/_services/controllers/conta-profissional-controller.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MASCARA_CPF, MASCARA_CNPJ } from 'app/_constants/mascaras';
import { mascaraTelefone } from 'app/_functions/mascara-telefone';
import { PerfilAfiliadoDto } from 'app/_models/alterar-perfil/perfil-afiliado-dto';
import { takeUntil } from 'rxjs/operators';
import { AlterarPerfilService } from 'app/_services/controllers/alterar-perfil.service';
import { Subject } from 'rxjs';
import { stringParaEnum } from 'app/_functions/converte-string-para-enum';
import { environment } from 'environments/environment';
import { DadosPagamentoComponent } from './dados-pagamento/dados-pagamento.component';
import { DadosPagamentoAfiliadosControllerService } from 'app/_services/controllers/dados-pagamento-afiliados.service';

@Component({
    selector: 'meus-dados',
    templateUrl: './meus-dados.component.html',
    styleUrls: ['./meus-dados.component.css'],
})
export class MeusDadosComponent implements OnInit {
    public afiliado: PerfilAfiliadoDto;
    public currentUser: User;
    public idade: number;
    public mascaraCpfCnpj: string;
    public mascaraTelefone: string;
    private _unsubscribeAll: Subject<any>;
    public mostrarBtnDadosPagamento: boolean = false;
    public mostrarBtnAlert: boolean = false;

    constructor(
        private _alterarPerfilService: AlterarPerfilService,
        public _fuseProgressBarService: FuseProgressBarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _authenticationService: AuthenticationService,
        private _dialogService: DialogService,
        private _contaProfissionalControllerService: ContaProfissionalControllerService,
        private _contaColaboradorControllerService: ContaColaboradorControllerService,
        private _toastService: ToastService,
        private _i18n: TraducaoService,
        private _dadosPagamentoAfiliadosControllerService: DadosPagamentoAfiliadosControllerService
    ) {
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(portugues);
    }

    ngOnInit(): void {
        this.selecionarDadosContaUsuario();
        this.verificarAlteracoesFoto();
        this.verificarAlteracoesAfiliado();
        this.mostrarBtnDadosPagamento = this.usuario.perfil == RoleUsuario.AFILIADO;
        this.mostrarBtnAlert = this.usuario.perfil === RoleUsuario.AFILIADO && !this.usuario.dadosPagamento;
    }

    private get usuario(): User {
        return this._authenticationService.currentUserValue;
    }

    private get roleUsuarioMeuConsultorio(): RoleUsuario {
        if (!!this.usuario) {
            const stringValueEnum = this.usuario.customProperties.RoleMeuConsultorio;
            return stringParaEnum<typeof RoleUsuario, RoleUsuario>(RoleUsuario, stringValueEnum);
        }
    }

    public get codigoAfiliado(): string {
        return this.usuario.customProperties.CodigoDivulgacao;
    }

    public get linkDivulgacao(): string {
        return `${environment.linkDivulgacao}${this.codigoAfiliado}`;
    }

    public isCnpj(): boolean {
        return this.afiliado.cpf != null && this.afiliado.cpf.length > 11;
    }

    public setMascaraCpfCnpj(): void {
        this.mascaraCpfCnpj = this.isCnpj() ? MASCARA_CNPJ : MASCARA_CPF;
    }

    public alterarPerfil(): void {
        this._dialogService.dialogGenerico(AlterarPerfilComponent, {
            data: {
                afiliado: this.afiliado,
                currentUser: this.currentUser,
                roleUsuarioMeuConsultorio: this.roleUsuarioMeuConsultorio,
                bloquearCancelar: false,
            },
        });
    }

    public alterarSenha(): void {
        this._dialogService.dialogGenerico(AlterarSenhaComponent, {
            data: {
                roleUsuarioMeuConsultorio: this.roleUsuarioMeuConsultorio,
            },
        });
    }

    private selecionarDadosContaUsuario(): void {
        if (RoleUsuario.ADMIN === this.roleUsuarioMeuConsultorio) {
            this._contaProfissionalControllerService.selecionarContaProfissional().subscribe(
                (res) => {
                    this.afiliado = res;
                    this.setMascaraCpfCnpj();
                    this.setMaskTelefone();
                },
                (err) => {
                    this._fuseProgressBarService.hide();
                    console.log(err);
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                }
            );
        } else {
            this._contaColaboradorControllerService.selecionarContaColaborador().subscribe(
                (res) => {
                    this.afiliado = res;
                    this.setMascaraCpfCnpj();
                    this.setMaskTelefone();
                },
                (err) => {
                    this._fuseProgressBarService.hide();
                    console.log(err);
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                }
            );
        }
    }

    public verificarAlteracoesAfiliado(): void {
        this._alterarPerfilService.alteracoesAfiliado.pipe(takeUntil(this._unsubscribeAll)).subscribe((afiliado) => {
            this.afiliado = afiliado;
        });
    }

    public verificarAlteracoesFoto(): void {
        this._alterarPerfilService.foto.pipe(takeUntil(this._unsubscribeAll)).subscribe((url) => {
            this.afiliado.caminhoFoto = url;
        });
    }

    private setMaskTelefone(): void {
        this.mascaraTelefone = mascaraTelefone(this.afiliado.telefone);
    }

    public exibirCodigo(): boolean {
        if (!!this.usuario) {
            return this.usuario.perfil === RoleUsuario.AFILIADO;
        }
        return false;
    }

    public modalDadosPagamento(): void {
        this._fuseProgressBarService.show();
        this._dadosPagamentoAfiliadosControllerService.selecionar().subscribe(
            (res) => {
                this._fuseProgressBarService.hide();
                this._dialogService.dialogGenerico(DadosPagamentoComponent, {
                    disableClose: false,
                    data: res.dados,
                });
            },
            (err) => {
                this._fuseProgressBarService.hide();
                console.log(err);
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
            }
        );
    }
}
