export const environment = {
    production: true,
    hmr: false,
    baseUrl: '/afiliados/app/',
    linkDivulgacao: 'https://app.amplum.solutions/registrar?afiliado=',
    apiUrl: 'https://afiliados.amplum.solutions/api/api/',
    firebase: {
        apiKey: 'AIzaSyCmfCknTokucqqUvt21w5EFfcsQHjq2vQo',
        authDomain: 'meu-consultorio-app.firebaseapp.com',
        databaseURL: 'https://meu-consultorio-app.firebaseio.com',
        projectId: 'meu-consultorio-app',
        storageBucket: 'meu-consultorio-app.appspot.com',
        messagingSenderId: '712106092529',
        server_key:
            // tslint:disable-next-line: max-line-length
            'AAAApczUp_E:APA91bFQEsDuaFlpArVKQO_L_05CoJL649YC-niseXAC9c2x5PCY3sXd1xsGG_QN9kafFwWvaNsHRFnxoT67slvQ8-svivCn15VEMcKYGMCflQNDEfGuPzPjFxK-HAYJTxrVVblUF0hO',
    },
    firebase_fcm: {
        apiPostFcmUrl: 'https://fcm.googleapis.com/fcm/send',
    },
    facebook: {
        id: '116345109816944',
    },
    pathImages: {
        logo: 'https://amplum.solutions/cdn/img/clinica/locaisAtendimentos/no-logo.png',
    },
};