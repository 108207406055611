import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ToastService, TraducaoService } from 'app/_services';
import { ContaProfissionalControllerService } from 'app/_services/controllers/conta-profissional-controller.service';
import { ContaColaboradorControllerService } from 'app/_services/controllers/conta-colaborador-controller.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as portugues } from 'app/_i18n/pt-br';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

@Component({
    selector: 'alterar-senha',
    templateUrl: './alterar-senha.component.html',
})
export class AlterarSenhaComponent implements OnInit {
    public form: FormGroup;
    public validaLetra: boolean;
    public validaNum: boolean;
    public validaIgual: boolean;
    public validaSenhaForm: boolean = true;
    public formValid: boolean = false;
    public roleUsuarioMeuConsultorio: RoleUsuario;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _toastService: ToastService,
        private _contaProfissionalControllerService: ContaProfissionalControllerService,
        private _contaColaboradorControllerService: ContaColaboradorControllerService,
        private _i18n: TraducaoService,
        public dialogRef: MatDialogRef<AlterarSenhaComponent>
    ) {
        this._fuseTranslationLoaderService.loadTranslations(portugues);
    }

    ngOnInit(): void {
        this.roleUsuarioMeuConsultorio = this.data.roleUsuarioMeuConsultorio;
        this.setFormValidade();
    }

    private setFormValidade(): void {
        this.form = this._formBuilder.group({
            senhaAtual: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
            novaSenha: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(25),
                    Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$'),
                ],
            ],
            confirmaSenha: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
        });
    }

    /**
     * Faz as validações de senha.
     */
    public valida(): void {
        const novaSenha: string = this.form.get('novaSenha').value;
        const confirmaSenha: string = this.form.get('confirmaSenha').value;
        novaSenha.match('[a-zA-Z]') ? (this.validaLetra = true) : (this.validaLetra = false);
        novaSenha.match('[0-9]') ? (this.validaNum = true) : (this.validaNum = false);

        if (novaSenha !== '') {
            novaSenha === confirmaSenha ? (this.validaIgual = true) : (this.validaIgual = false);
        }

        if (this.validaLetra && this.validaNum && this.validaIgual && this.form.valid && this.validaSenhaForm) {
            this.formValid = true;
        } else {
            this.formValid = false;
        }
    }

    /**
     * Altera a senha.
     */
    public alterarSenha(): void {
        const senhaAtual = btoa(this.form.get('senhaAtual').value);

        if (this.roleUsuarioMeuConsultorio === RoleUsuario.ADMIN) {
            this._contaProfissionalControllerService.validarSenha(senhaAtual).subscribe((result) => {
                result === true ? (this.validaSenhaForm = true) : (this.validaSenhaForm = false);

                if (result === true) {
                    const senha = btoa(this.form.get('novaSenha').value);
                    this._contaProfissionalControllerService.alterarSenha(senha).subscribe(
                        () => {
                            this._toastService.mensagemSuccess('Sua senha foi alterada com sucesso!');
                            this.dialogRef.close();
                        },
                        (err) => {
                            this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                            console.log(err);
                        }
                    );
                } else {
                    this.validaSenhaForm = false;
                }
            });
        } else {
            this._contaColaboradorControllerService.validarSenha(senhaAtual).subscribe((result) => {
                result === true ? (this.validaSenhaForm = true) : (this.validaSenhaForm = false);

                if (result === true) {
                    const senha = btoa(this.form.get('novaSenha').value);
                    this._contaColaboradorControllerService.alterarSenha(senha).subscribe(
                        () => {
                            this._toastService.mensagemSuccess('Sua senha foi alterada com sucesso!');
                            this.dialogRef.close();
                        },
                        (err) => {
                            this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                            console.log(err);
                        }
                    );
                } else {
                    this.validaSenhaForm = false;
                }
            });
        }
    }
}
