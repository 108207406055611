// tslint:disable no-any
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AuthenticationService, ToastService, TraducaoService } from 'app/_services';
import { Router } from '@angular/router';
import { User } from 'app/_models';
import { NotificacaoFormatadaDto } from 'app/_models/notificacoes/notificacao-formatada-dto';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlterarPerfilService } from 'app/_services/controllers/alterar-perfil.service';
import { environment } from 'environments/environment';
import { FuseCopierService } from '@fuse/services/copier.service';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    src: string;
    _logoUsuario: string;
    // Private
    private _unsubscribeAll: Subject<any>;
    public notificacoesDto: BehaviorSubject<Array<NotificacaoFormatadaDto>> = new BehaviorSubject(Array<NotificacaoFormatadaDto>());
    public form: FormGroup;
    public mostrarBtnAlert: boolean = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     * @param {AuthenticationService} _authenticationService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private _alterarPerfilService: AlterarPerfilService,
        private _clipboard: FuseCopierService,
        private _toastService: ToastService,
        private _i18n: TraducaoService,
        private _formBuilder: FormBuilder,
        private _router: Router
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50',
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107',
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336',
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD',
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161',
            },
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr',
            },
        ];

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });
        console.log(environment);
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });

        if (!!this.usuario && !!this.usuario.caminhoFoto) {
            this._logoUsuario = this.usuario.caminhoFoto;
        } else {
            this._logoUsuario = 'assets/images/avatars/profile.jpg';
        }

        this.mostrarBtnAlert = this.usuario.perfil === RoleUsuario.AFILIADO && !this.usuario.dadosPagamento;

        this.formValidation();
        this.verificaAlteracoesAfiliado();
        this.verificaAlteracoesFoto();
    }

    public mostarBtbAlerta(mostrar: boolean = false) {
        this.mostrarBtnAlert = !this.usuario.dadosPagamento;
    }

    public formValidation(): void {
        let codigoAfiliado = '';
        let linkDivulgacao = '';

        if (!!this.usuario) {
            codigoAfiliado = this.usuario.customProperties.CodigoDivulgacao;
            linkDivulgacao = `${environment.linkDivulgacao}${codigoAfiliado}`;
        }

        this.form = this._formBuilder.group({
            codigoAfiliado: [codigoAfiliado],
            linkDivulgacao: [linkDivulgacao],
        });
    }

    get usuario(): User {
        return this._authenticationService.currentUserValue;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key: any): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value: object): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     */
    logout(): void {
        const pattern = /\/prontuarios\/paciente\/\d+/;

        if (pattern.test(this._router.url)) {
            this._router.navigate(['/login']);
        } else {
            this._router.navigate(['/login']);
            this._authenticationService.logout();
        }
    }

    public selecionarNomeUsuario(): string {
        if (!!this.usuario) {
            return this.usuario.nome.length > 35 ? this.usuario.nome.substr(0, 32) + '...' : this.usuario.nome;
        }

        return '';
    }

    public verificaAlteracoesFoto(): void {
        this._alterarPerfilService.foto.pipe(takeUntil(this._unsubscribeAll)).subscribe((url) => {
            const user: User = this._authenticationService.currentUserValue;
            user.caminhoFoto = url;
            this._authenticationService.currentUserValue = user;
            this._logoUsuario = url;
        });
    }

    public verificaAlteracoesAfiliado(): void {
        this._alterarPerfilService.alteracoesAfiliado.pipe(takeUntil(this._unsubscribeAll)).subscribe((afiliado) => {
            const user: User = this._authenticationService.currentUserValue;
            user.nome = afiliado.nome;
            this._authenticationService.currentUserValue = user;
            this.usuario.nome = afiliado.nome;
        });
    }

    public copiarCodigoAfiliado(): void {
        this._clipboard.copyText(this.selecionarCodigoAfiliado());
        this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.CODIGO_AFILIADO_COPIADO'));
    }

    public copiarLinkDivulgacao(): void {
        this._clipboard.copyText(this.selecionarLinkDivulgacao());
        this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.LINK_DIVULGACAO_COPIADO'));
    }

    public selecionarLinkDivulgacao(): string {
        if (!this.usuario) {
            return '';
        }

        return this.form.get('linkDivulgacao').value;
    }

    public selecionarCodigoAfiliado(): string {
        if (!this.usuario) {
            return '';
        }

        return this.form.get('codigoAfiliado').value;
    }

    public ehPerfilAfiliado(): boolean {
        return !!this.usuario && this.usuario.perfil === RoleUsuario.AFILIADO;
    }
}
