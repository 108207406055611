<h1 mat-dialog-title>Política de Privacidade ao Usuário dos Sistemas da WMI Tecnologia Ltda</h1>
<small>Versão 2, publicado em 14 de setembro de 2021.</small>
<mat-dialog-content>
    <div>
        <p>
            A Política de Privacidade ao Usuário dos <strong>Sistemas da WMI Tecnologia Ltda</strong> foi criada com o objetivo de mostrar o nosso
            compromisso em tratar os seus dados pessoais com segurança, privacidade e transparência.
        </p>
        <p>
            Esta Política de Privacidade descreve quais são os dados pessoais coletados, como são utilizados, armazenados e compartilhados e os seus
            direitos em relação a esses dados. Recomendamos que você faça uma leitura atenta.
        </p>
        <p><br /></p>
        <p><strong>1. Abrangência</strong></p>
        <p>
            Esta Política de Privacidade é aplicável a todas as pessoas e entidades que tem o seu cadastro (dados pessoais) em quaisquer
            <strong>Sistemas da WMI Tecnologia Ltda</strong>.&nbsp;
        </p>
        <p>Esta política descreve como nós, na qualidade de controladores ou operadores tratamos os seus dados.</p>
        <p>
            Na qualidade de <strong>controlador</strong>, nós coletamos os seus dados pessoais quando você (i) se cadastra em nossos sistemas ou (ii)
            quando te cadastramos, ambos em uma relação direta com a WMI Tecnologia Ltda.
        </p>
        <p>
            Na qualidade de <strong>operador</strong>, nós tratamos os seus dados pessoais quando um controlador - geralmente uma empresa prestadora
            de serviços de saúde (laboratórios, clínicas ou consultórios) nos contrata para tal, utilizando-se de nossos sistemas informáticos para o
            processamento de informações e serviços correlatos.
        </p>
        <p>
            Nós também coletamos os seus dados pessoais sempre que você deseja iniciar um relacionamento profissional com a WMI Tecnologia Ltda ou
            quando uma empresa lhe cadastra como seu representante/usuário.
        </p>
        <p>
            Nós operacionalizamos o tratamento de dados pessoais em nome de empresas prestadoras de serviços de saúde (laboratórios, clínicas ou
            consultórios), sendo que estes agentes coletarão seus dados sempre que:
        </p>
        <ol type="i" start="1">
            <li>For realizar ou atualizar seu cadastro (inclusive de forma biométrica, quando a Lei ou regulamento assim o exigir);</li>
            <li>Processar dados relativos à sua saúde ou aos serviços correlatos à prestação de serviços médicos, clínicos ou hospitalares.</li>
        </ol>
        <p>Podemos tratar seus dados, ainda, na qualidade de operadores do tratamento de dados, em nome de um controlador, sempre que:</p>
        <ol type="i" start="3">
            <li>Acessar o sistema para um agendamento de exame ou consulta;</li>
            <li>Acessar o sistema para consulta de resultados de exames;</li>
            <li>Validar seus dados para fins de segurança, inclusive biométrica;</li>
            <li>Armazenar e processar seus dados dentro de nossos sistemas <em>on-line</em> (SaaS);</li>
            <li>
                Compartilhar seus dados com outros prestadores de serviços de saúde, nos termos do seu contrato com o controlador, observados os
                limites da Lei (art. 11, §4º, LGPD);
            </li>
            <li>Guardá-los até o fim do período do tratamento dos dados (período de guarda legal das informações de tratamento e de uso);</li>
        </ol>
        <p>
            As práticas descritas nesta Política de Privacidade se aplicam ao tratamento dos seus dados pessoais no Brasil e estão sujeitas às leis
            locais aplicáveis - LGPD - Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018).
        </p>
        <p><strong>2. Dados pessoais coletados pelos Sistemas da WMI Tecnologia Ltda</strong></p>
        <p>
            Ao se cadastrar e utilizar os Sistemas da WMI Tecnologia Ltda você (ou um cliente nosso) fornece os seus dados pessoais para que seja
            possível a realização das atividades do sistema. No caso das Empresas que utilizam o cadastro dos seus funcionários e pacientes, estas
            informações também recebem os mesmos tratamentos de dados pessoais.
        </p>
        <p>
            A partir do momento em que você – na qualidade de paciente ou usuário de nossos sistemas - aceita os termos desta Política de Privacidade,
            você concorda expressamente em fornecer apenas dados pessoais verdadeiros, atualizados e corretos e em não alterar a identidade ou dados
            pessoais de qualquer forma no acesso e na utilização dos nossos serviços ou sistemas.
            <strong
                >Você é o único responsável pelas informações falsas, desatualizadas ou imprecisas que fornecer nos sistemas da WMI Tecnologia
                Ltda.</strong
            >
        </p>
        <p>
            Você será o único e exclusivo responsável pela veracidade dos Dados Pessoais fornecidos na realização do seu cadastro, operações e/ou
            contratação de eventuais produtos ou serviços. A WMI Tecnologia Ltda não possui qualquer responsabilidade pela veracidade dos dados
            fornecidos, bem como por eventuais danos decorrentes da inexatidão e/ou desatualização de referidas informações ou pelo mau uso dos
            sistemas e dos dados pessoais tratados por agentes de tratamento, sendo nosso dever resultante de contrato com controladores ou
            responsabilidade direta por atos de nossos agentes.
        </p>
        <p>Estes são os dados pessoais coletados e utilizados pelos Sistemas da WMI Tecnologia Ltda:</p>
        <ul style="list-style: none">
            <li>
                <strong>Dados cadastrais</strong>: nome, documentos de identificação, nacionalidade, endereço, data de nascimento, filiação, gênero,
                entre outros.
            </li>
            <br />
            <li><strong>Dados de contato</strong>: telefone e e-mail.</li>
            <br />
            <li><strong>Dados biométricos</strong>: fotografia de cadastro;</li>
            <br />
            <li>
                <strong>Dados de localização</strong>: também coletamos os dados de uso dos serviços de localização, para aprimorar a prestação de
                serviços.
            </li>
            <br />
            <li>
                <strong>Dados de saúde</strong>: todos aqueles relacionados e imprescindíveis à prestação de serviços médicos, na forma da legislação
                de regência e nos seus limites, a critério dos controladores e nos padrões da técnica aplicável e regulamentar.
            </li>
        </ul>
        <p>
            Estes dados também podem ser coletados durante o uso dos nossos serviços em Aplicativos ou websites (navegação), sendo que estas
            informações são coletadas quando você navega e/ou utiliza os serviços da WMI Tecnologia Ltda por intermédio de nossos sistemas e
            aplicativos.
        </p>
        <p>
            Também podemos coletar seus dados de navegação, sobre as suas interações com o Aplicativo ou website, tais como: histórico de pesquisas,
            histórico de navegação, datas e horários de acesso, recursos utilizados, a forma com que utiliza os produtos e serviços, problemas do
            Aplicativo e outras atividades do sistema.&nbsp;
        </p>
        <p>
            Essas informações são importantes para um melhor desempenho das funcionalidades disponibilizadas no site e Aplicativo e para possibilitar
            a exibição de conteúdos relevantes pra você. Sempre que viável, estes dados de uso serão anonimizados (sem que se possa, portanto, saber
            quem você é).
        </p>
        <p>
            Em alguns casos, podemos fazer essa coleta por meio de Cookies, ferramentas importantes para o perfeito funcionamento de nossos serviços.
            Assim, você deve estar ciente de que, caso opte por recusar ou remover os Cookies, poderá ter a disponibilidade e a funcionalidade dos
            serviços afetadas.&nbsp;
        </p>
        <p>
            Dispositivos: Coletamos, também, em muitos casos, informações sobre o Dispositivo que você utiliza para acessar o Aplicativo e Website,
            tais como: marca, sistema operacional, endereço de IP, tipo de conexão de rede (WI-FI, 3G, LTE, Bluetooth ou outros), desempenho da rede e
            do Dispositivo, tipo de navegador, versão, idioma e versão do Aplicativo. Estas informações são necessárias para garantir a
            compatibilidade de nossos serviços com o Dispositivo que você utiliza e, assim, permitir uma melhor experiência.
        </p>
        <p>
            <strong
                >Sempre que um dado de geolocalização for coletado ele o será por processo de pseudoanonimização (em que não é possível determinar
                quem é o usuário de forma direta) e, para uso deste dado pessoal, ele será sempre anonimizado (processo que não permite reversão e que
                torna anônimo o usuário). Isso implica no nosso compromisso em não fornecer ou criar perfis socioeconômicos e somente para as
                finalidades do tratamento de dados (serviços de saúde e atividades correlatas).&nbsp;</strong
            >
        </p>
        <p>
            Conseguimos determinar sua localização pelos seguintes meios: GPS (cadastro no Aplicativo). Os tipos de dados de localização que coletamos
            dependem, em parte, do Dispositivo e das configurações do Aplicativo. É possível ativar ou desativar o rastreio de sua localização
            acessando a opção Ajustes/Privacidade do seu Dispositivo.
        </p>
        <p>
            Dados coletados de outras fontes: são as informações coletadas pela WMI Tecnologia Ltda de empresas parceiras e clientes, fornecedores e
            prestadores de serviços, bem como de seu empregador, nos casos em que necessário para a execução de nosso contrato de licença de uso
            respeitando as finalidades previstas nesta regulamentação e no contrato padrão de licença de uso de software e serviços da WMI Tecnologia
            Ltda. Essas empresas podem mudar conforme os serviços oferecidos pela WMI Tecnologia Ltda e as informações coletadas.
        </p>
        <p>
            A WMI Tecnologia Ltda, na qualidade de controlador, somente coletará seus dados para publicidade e marketing se você for um usuário
            profissional e concordar, quando de seu cadastro, com tal finalidade.
        </p>
        <p>
            As bases legadas de dados da WMI Tecnologia Ltda, quando em vigor a LGPD, serão utilizadas nos estritos limites das permissões legais,
            sempre facultando ao usuário o exercício dos direitos legais inerentes aos dados pessoais.&nbsp;
        </p>
        <p>
            <strong
                >As bases de dados dos serviços de saúde pertencem às empresas e profissionais de saúde que executam os serviços respectivos, sendo
                que a WMI Tecnologia Ltda não possui acesso ao conteúdo das bases de dados. Qualquer requisição de tratamento de dados deverá ser
                feita, portanto, ao prestador de serviços de saúde do qual você é paciente/cliente. Em casos de manutenção de bases de dados, todas as
                informações estarão sujeitas a processos de anonimização irreversível antes de sua transmissão.</strong
            >
        </p>
        <p><strong>3. Por que coletamos seus dados pessoais</strong></p>
        <p>
            Seus dados pessoais são coletados e tratados para viabilizar (i) o exercício da atividade da WMI Tecnologia Ltda, em todas as suas frentes
            (operações, comercial, marketing, administrativo, RH, legal, etc.); e (ii) a prestação de serviços de saúde das empresas, clínicas,
            profissionais médicos e hospitais que são nossos clientes: cadastro de usuários, serviços de saúde, serviços correlatos à área médica e
            hospitalar, operacionalização de serviços de saúde por intermédio de terceiros, controle de fraudes, contrato de prestação de serviços e
            melhorias do sistema.&nbsp;
        </p>
        <p><br /></p>
        <p>
            Seus dados são coletados e tratados pelas empresas e profissionais de saúde por meios de sistemas de tecnologia da informação online e
            presencial, podendo o tratamento ser realizado por operadores na forma da Lei – Sistemas da WMI Tecnologia Ltda - , sendo esta a base
            legal e permissão para sermos legalmente considerados o operador do tratamento de dados.
        </p>
        <p>Se você for uma criança/adolescente seus dados somente poderão ser tratados com permissão expressa do responsável legal.</p>
        <p>
            <strong
                >Sempre que a Lei o exigir, a empresa ou profissional de saúde controladora dos dados será obrigada a coletar o seu consentimento, o
                que deverá ser realizado de forma simples e acessível, na forma da Lei, sendo responsável deste controlador a coleta e administração
                deste consentimento.</strong
            >
        </p>
        <p><br /></p>
        <p>Os Dados Pessoais coletados, nos termos da presente Política, são utilizados para as seguintes finalidades<strong>:</strong></p>
        <ul>
            <li>
                cumprir com nossas obrigações contratuais, em especial a execução dos termos de nossos contratos de licença de uso pelas entidades e
                profissionais de saúde;&nbsp;
            </li>
            <li>cumprir requisitos legais e/ou regulamentares aplicáveis;</li>
            <li>fornecer a você os nossos produtos e serviços;</li>
            <li>para notificá-lo sobre eventuais alterações em nossos produtos e serviços;</li>
            <li>reforçar nossos procedimentos de segurança e proteção, visando a prestação de um serviço mais seguro e eficaz;&nbsp;</li>
            <li>administrar nossa prestação de serviços e/ou fornecimento de produtos;</li>
            <li>
                realizar operações internas, incluindo suporte aos clientes e outros titulares de dados, solução de problemas, análise de dados,
                testes, pesquisas e estatística – com as limitações desta política;
            </li>
            <li>melhorar e aperfeiçoar nossos serviços e produtos, garantindo que eles sejam apresentados da maneira mais eficaz a você;</li>
            <li>
                avaliar ou entender a eficácia da publicidade que veiculamos, visando fornecer publicidades relevantes para você, protegendo seus
                direitos;
            </li>
            <li>fornecer informações sobre outros serviços e/ou produtos que oferecemos;</li>
            <li>ofertar produtos ou serviços adequados e relevantes aos seus interesses;</li>
            <li>
                produzir provas e auxiliar na condução de processos jurídicos, administrativos ou arbitrais, bem como auxiliar no cumprimento de
                outros requisitos legais.
            </li>
        </ul>
        <p>
            Caso você queira saber mais detalhes sobre como os seus Dados Pessoais serão tratados pela WMI Tecnologia Ltda com base nas finalidades
            descritas nesse item, entre em contato conosco por meio dos nossos <strong>canais de atendimento</strong>.
        </p>

        <p>
            Todos os dados que você nos fornece ativamente ou que coletamos são considerados confidenciais. Assim, nos comprometemos a adotar todas as
            medidas técnicas e administrativas aptas a proteger seus Dados Pessoais, observando, por exemplo, as diretrizes sobre padrões de segurança
            estabelecidas na legislação vigente.
        </p>

        <p>
            Via de regra, todo o tráfego de dados é realizado de forma criptografada e empreendemos, por meio da nossa
            <strong>Política de Segurança da Informação</strong>, meios técnicos adequados à proteção dos dados.
        </p>
        <p><br /></p>
        <p><strong>4. Compartilhamento de dados pessoais</strong></p>
        <p>
            Os dados informados nos Sistemas da WMI Tecnologia Ltda, sempre que este é o operador do tratamento de dados, poderão ser compartilhados,
            a critério do controlador:
        </p>
        <p>
            (i) Se você for um paciente, com outras empresas e profissionais de saúde para a execução do contrato de prestação de serviços médicos ou
            laboratoriais de nossos clientes, na forma do art. 7º, V e VIII, art. 11, II, “f” e §4º.
        </p>
        <p>
            &nbsp;(ii) se você for um usuário empregado de empresa titular do contrato de licença seus dados poderão ser compartilhados para controle
            financeiro e operacional e prevenção de fraudes;
        </p>
        <p>(iii) caso você solicite.</p>
        <p>
            Nos casos em que a WMI Tecnologia Ltda não é o operador do tratamento de dados, você deverá verificar atentamente a política de
            privacidade da empresa controladora dos dados, via de regra, a empresa ou profissional que presta serviços de saúde.&nbsp;&nbsp;
        </p>
        <p>
            Se você estiver em dúvidas sobre quem é esta entidade, entre em contato conosco por meio dos nossos
            <strong>canais de atendimento</strong>.
        </p>
        <p>
            Informamos ainda que este compartilhamento se dará sempre dentro dos limites permitidos em Lei e, sempre que viável tecnicamente, de forma
            anonimizada (sem que você possa ser identificado).
        </p>
        <p><br /></p>
        <p><strong>5. Duração do Tratamento dos Dados Pessoais</strong></p>
        <p>O prazo de Tratamento dos seus Dados Pessoais pela WMI Tecnologia Ltda variará de acordo:</p>
        <ol type="i">
            <li>com os tipos de produtos e serviços contratados/prestados/fornecidos;</li>
            <li>com as finalidades do tratamento;</li>
            <li>com as disposições contratuais e legais em questão.</li>
        </ol>
        <p>
            Assim, seus Dados Pessoais deverão ser excluídos pela WMI Tecnologia Ltda ou pelo controlador dos dados, exceto se seu armazenamento deva
            ser feito em razão de obrigação legal ou regulamentar, quando:
        </p>
        <ol type="i">
            <li>
                A finalidade para a qual a informação foi coletada seja alcançada ou quando os Dados Pessoais deixarem de ser necessários ou
                pertinentes para o alcance desta finalidade;
            </li>
            <li>Quando houver revogação do consentimento;</li>
            <li>Mediante determinação de autoridade competente para tanto ou requerimento seu.</li>
        </ol>
        <p>
            Durante o período em que retivermos seus dados pessoais, estes serão armazenados dentro das condições mínimas da Segurança da Informação,
            com os pilares de confidencialidade, integridade e disponibilidade. Sempre que possível ou tecnicamente viável, estes dados serão
            anonimizados.
        </p>
        <p><br /></p>
        <p><strong>6. Revogação do consentimento&nbsp;</strong></p>
        <p>
            Nos casos em que nossos clientes necessitem de seu consentimento para o tratamento dos dados, o titular de dados pessoais tratados por
            qualquer sistema da WMI Tecnologia Ltda terá o direito de revogar o consentimento fornecido, o que deverá ser realizado junto à entidade
            controladora dos dados.
        </p>
        <p>
            Sem o consentimento para o tratamento dos dados não será possível a utilização de quaisquer Sistemas para as finalidades que solicitam o
            consentimento (p.ex., o tratamento de dados de crianças e adolescentes).
        </p>
        <p>
            Caso você queira exercer este direito, você deverá entrar em contato com o Controlador dos Dados Pessoais, geralmente a empresa ou
            profissional que presta serviços de saúde.
        </p>
        <p>
            Se você estiver em dúvidas sobre quem é esta entidade, entre em contato conosco por meio dos nossos
            <strong>canais de atendimento</strong>.
        </p>
        <p><br /></p>
        <p><strong>7. Os Seus Direitos como Titular dos Dados Pessoais</strong></p>
        <p>
            A Lei Geral de Proteção de Dados confere uma série de direitos aos Titulares dos Dados. Sendo assim, você, como Titular dos Dados, pode
            fazer as solicitações ao controlador dos dados¹, conforme detalhado na tabela abaixo:
        </p>
        <p>
            ¹Se você estiver em dúvidas sobre quem é esta entidade, entre em contato conosco por meio dos nossos
            <strong>canais de atendimento</strong>.
        </p>
        <table>
            <tbody>
                <tr>
                    <td>
                        <p><strong>Seu Direito</strong></p>
                    </td>
                    <td style="text-align: center !important">
                        <p><strong>O Que Isto Significa</strong></p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Confirmação da existência de Tratamento</strong></p>
                    </td>
                    <td>
                        <p>
                            Você pode solicitar à WMI Tecnologia Ltda que confirme que os seus Dados Pessoais estão sendo tratados e qual o tipo de
                            tratamento que está sendo feito. Esta hipótese somente é aplicável para dados coletados por nós, enquanto controladores.
                            Caso sejamos operadores do tratamento de dados, você deve entrar em contato com o profissional ou entidade prestadora de
                            serviços de saúde.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Acesso aos seus Dados Pessoais</strong></p>
                    </td>
                    <td>
                        <p>
                            Você pode solicitar um resumo dos Dados Pessoais que coletamos, com informações de como eles são tratados e com qual
                            finalidade. Forneceremos, por meios eletrônicos ou físicos, uma cópia dos Dados Pessoais que mantemos sobre você. Esta
                            hipótese somente é aplicável para dados coletados por nós, enquanto controladores. Caso sejamos operadores do tratamento
                            de dados, você deve entrar em contato com o profissional ou entidade prestadora de serviços de saúde.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Correção de seus Dados Pessoais</strong></p>
                    </td>
                    <td>
                        <p>
                            Você pode editar, solicitar a correção ou atualização de seus Dados Pessoais, quando estes estiverem errados, incompletos
                            ou desatualizados.
                        </p>
                        <br />
                        <p>
                            Antes de atualizarmos seus Dados Pessoais, podemos solicitar documentos e/ou informações que comprovem as novas
                            informações que você forneceu.
                        </p>
                        <br />
                        <p>
                            Esta hipótese somente é aplicável para dados coletados por nós, enquanto controladores. Caso sejamos operadores do
                            tratamento de dados, você deve entrar em contato com o profissional ou entidade prestadora de serviços de saúde.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Solicitar a anonimização, bloqueio ou eliminação dos seus Dados Pessoais</strong></p>
                    </td>
                    <td>
                        <p>
                            Você pode solicitar que seus Dados Pessoais sejam anonimizados, bloqueados ou que sejam eliminados das bases da WMI
                            Tecnologia Ltda.
                        </p>
                        <br />
                        <p>
                            Você poderá nos solicitar a eliminação dos Dados quando: (i) acredita que não há razão para continuarmos a usá-los; (ii)
                            deseja retirar o consentimento (permissão) que nos deu em um primeiro momento; (iii) se opuser ao nosso uso das
                            informações; (iv) você considera que usamos as informações ilegalmente; (v) a lei exige que excluamos as informações.
                        </p>
                        <br />
                        <p>
                            Nós não atenderemos a sua solicitação de eliminação quando a WMI Tecnologia Ltda deva armazenar tais Dados Pessoais, em
                            razão de obrigação legal ou regulamentar.
                        </p>
                        <br />
                        <p>
                            Esta hipótese somente é aplicável para dados coletados por nós, enquanto controladores. Caso sejamos operadores do
                            tratamento de dados, você deve entrar em contato com o profissional ou entidade prestadora de serviços de saúde.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Portabilidade de seus Dados Pessoais</strong></p>
                    </td>
                    <td>
                        <p>Você pode solicitar a migração das informações cadastradas para outra organização.</p>
                        <br />
                        <p>
                            O atendimento desta solicitação irá considerar as disposições legais vigentes e os Dados Pessoais, quando fornecidos,
                            serão de forma estruturada, em um formato comumente usado e legível pelos sistemas.
                        </p>
                        <br />
                        <p>Não podemos compartilhar Dados Pessoais que sejam sigilo ou segredo de negócios.</p>
                        <br />
                        <p>
                            Esta hipótese somente é aplicável para dados coletados por nós, enquanto controladores. Caso sejamos operadores do
                            tratamento de dados, você deve entrar em contato com o profissional ou entidade prestadora de serviços de saúde.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Informações sobre o compartilhamento dos seus Dados Pessoais</strong></p>
                    </td>
                    <td>
                        <p>
                            Você pode solicitar informações sobre com quem (terceiros ou parceiros) a WMI Tecnologia Ltda, na qualidade de operador,
                            compartilha seus Dados Pessoais.
                        </p>
                        <br />
                        <p>Esta hipótese somente é aplicável para dados que não tenham sido anonimizados.&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Possibilidade de não fornecimento do consentimento</strong></p>
                    </td>
                    <td>
                        <p>
                            Caso você se recuse a fornecer o consentimento para acesso ou Tratamento de seus Dados Pessoais, nós te informaremos sobre
                            as consequências desta recusa que, geralmente, redundam na inviabilidade de prestação dos nossos serviços.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Revogar consentimentos</strong></p>
                    </td>
                    <td>
                        <p>Se você consentiu com o Tratamento de seus Dados Pessoais, você poderá, a qualquer momento, cancelar tal consentimento.</p>
                        <p>
                            Reforçamos que será lícito usarmos os seus Dados Pessoais até o momento da revogação do consentimento, bem como será
                            lícito o arquivamento desses Dados Pessoais por prazo superior ao do tratamento, sempre que for necessário para
                            cumprimento de obrigação legal ou regulatória.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Oposição ao processamento dos seus Dados Pessoais</strong></p>
                    </td>
                    <td>
                        <p>
                            Você pode se opor ao Tratamento dos seus Dados Pessoais quando este estiver em desacordo ou descumprindo qualquer
                            determinação da LGPD.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Revisão de decisão automatizada</strong></p>
                    </td>
                    <td>
                        <p>
                            Você pode solicitar que decisões tomadas unicamente com base em Tratamento automatizado de Dados Pessoais que afetem seus
                            interesses sejam revistas.
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p><br /></p>
        <p>
            Para exercer qualquer um dos seus direitos estabelecidos acima, bem como determinar suas preferências no tratamento e utilização pela WMI
            Tecnologia Ltda de alguns de seus dados pessoais, você deve entrar em contato conosco por meio dos nossos
            <strong>canais de atendimento.</strong>
        </p>
        <p>Você receberá uma resposta em, no máximo, 15 (quinze) dias contados da data da sua solicitação.</p>
        <p>
            Por motivos de segurança, somente poderemos atender à sua solicitação se tivermos certeza da sua identidade. Sendo assim, poderemos
            solicitar dados ou informações adicionais para a confirmação da identidade e da autenticidade do requisitante.
        </p>
        <p><br /></p>
        <p><strong>8. Disposições Gerais</strong></p>
        <p>
            Os termos desta Política poderão ser modificados a qualquer momento, em razão de alterações legislativas ou nos produtos ou serviços que
            prestamos, em decorrência da atualização de ferramentas tecnológicas ou, quando necessário, a nosso critério, como por exemplo em razão da
            oferta de novos produtos ou serviços.&nbsp;
        </p>
        <p>
            Quando realizarmos alterações relevantes, os Titulares dos Dados serão notificados por meio de aviso em nossos Sistemas, Website, e-mail
            ou por qualquer outro meio de comunicação disponível, a critério da WMI Tecnologia Ltda. Certifique-se de ler atentamente qualquer aviso
            desta natureza.
        </p>
        <p>
            Suas informações serão sempre processadas em conformidade com esta Política. Nunca reduziremos seus direitos estabelecidos nesta Política
            sem seu consentimento explícito e em desconformidade com a Lei de Proteção de Dados.
        </p>
        <p>
            Qualquer cláusula ou condição desta Política que, por qualquer razão, venha a ser reputada nula ou ineficaz por qualquer juízo ou
            tribunal, não afetará a validade das demais disposições desta Política, as quais permanecerão plenamente válidas e vinculantes, gerando
            efeitos em sua máxima extensão.
        </p>
        <p>
            A falha da WMI Tecnologia Ltda em exigir quaisquer direitos ou disposições desta Política não constituirá renúncia, podendo esta exercer
            regularmente o seu direito, dentro dos prazos legais.
        </p>
        <p>
            Esta Política é regida exclusivamente pelas Leis da República Federativa do Brasil, bem como eventuais ações decorrentes de violação
            destes Termos.
        </p>
        <p>
            Eventuais dúvidas sobre esta Política, podem ser tratadas com o nosso <strong>Encarregado pela Proteção de Dados Pessoais</strong> pelo
            e-mail <strong><a target="_blank" href="mailto:lgpd@wmi.solutions" style="color: #667799">lgpd@wmi.solutions</a></strong
            >. Ele está à disposição em dias úteis de 8h às 17h e você receberá uma resposta no prazo máximo de 15 dias. Você também poderá ter acesso
            a estes serviços nos telefones abaixo indicados ou, ainda, por correspondência:
        </p>
        <ul style="list-style: none">
            <li>Encarregado pela Proteção de Dados Pessoais:</li>
            <li>Nome: Ingrid Fernandes</li>
            <li>
                E-mail: <b><a target="_blank" href="mailto:lgpd@wmi.solutions" style="color: #667799">lgpd@wmi.solutions</a></b>
            </li>
            <li>Av. R. Fortaleza, 170 - Industrial, Divinópolis - MG, 35.500-198.</li>
            <li>Tel.: 37 2102-5532.</li>
        </ul>
        <p>
            A utilização dos nossos serviços implicará em expressa aceitação quanto aos termos e condições da Política de Privacidade vigente na data
            de sua utilização. Para os titulares de dados que não concordem com a Política de Privacidade vigente, recomendamos a não utilização dos
            nossos produtos ou serviços. A não aceitação ou a recusa em disponibilizar as informações solicitadas, pode impedir a prestação de tais
            produtos ou serviços.
        </p>
        <p><strong>9. Lei Aplicável e Resolução de Conflitos</strong></p>
        <p>
            Toda e qualquer controvérsia oriunda dos termos expostos na presente Política de Privacidade serão solucionados de acordo com a lei
            brasileira, sendo competente o foro da cidade de Belo Horizonte - MG, com exclusão de qualquer outro por mais privilegiado que seja.
        </p>
        <p>
            A utilização de serviços e/ou ordens comandadas fora do território brasileiro, conforme o caso, ou ainda as decorrentes de operações
            iniciadas no exterior podem estar sujeitas também à legislação e jurisdição das autoridades dos países onde forem comandadas ou
            iniciadas.<br />
        </p>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="container" fxLayout="row" fxLayoutAlign="end" fxLayoutAlign.xs="center center">
    <button mat-raised-button [mat-dialog-close]="true" color="accent">OK, ENTENDI</button>
</mat-dialog-actions>
