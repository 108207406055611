import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ListaColunas } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { WIDTH_XS } from 'app/_constants/tamanhos-telas';
import { getCpfFormatado } from 'app/_functions';
import { dataExtenso } from 'app/_functions/data-extenso';
import { formatarTelefone } from 'app/_functions/formatar-telefone';
import { formatarCep } from 'app/_functions/formatar-cep';
import { Indicacoes } from 'app/_models/conta-profissional';
import { DadosDetalheAfiliado } from 'app/_models/conta-profissional-afiliado/dados-detalhe-afiliado';
import { DadosAfiliadoEnderecoDTO } from 'app/_models/conta-profissional-afiliado/dados-afiliado-endereco-dto';
import { DadosBasicoUsuarioDTO } from 'app/_models/conta-profissional-afiliado/dados-basicos-usuario-dto';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { ToastService, TraducaoService } from 'app/_services';
import { ContaProfissionalAfiliadoControllerService } from 'app/_services/controllers/conta-profissional-afiliado-controller.service';

@Component({
    selector: 'app-detalhe-afiliado',
    templateUrl: './detalhe.component.html',
    styleUrls: ['./detalhe.component.scss'],
})
export class DetalheComponent implements OnInit {
    @Input() dados: DadosBasicoUsuarioDTO;
    @Output() acaoFechar: EventEmitter<void> = new EventEmitter();
    public colunas: Array<ListaColunas> = new Array<ListaColunas>();
    public itens: Array<DadosDetalheAfiliado>;
    public informacaoConta: DadosAfiliadoEnderecoDTO = new DadosAfiliadoEnderecoDTO();
    public formatarTelefone: CallableFunction = formatarTelefone;
    public getCpfFormatado: CallableFunction = getCpfFormatado;
    public formatarCep: CallableFunction = formatarCep;

    constructor(
        public fuseProgressBarService: FuseProgressBarService,
        private _i18n: TraducaoService,
        private _contaProfissionalAfiliadoControllerService: ContaProfissionalAfiliadoControllerService,
        private _toastService: ToastService
    ) {}

    ngOnInit() {
        this.itens = [];
        this._colunasBase();
        this._listarDados();
    }

    private _listarDados(): void {
        this.fuseProgressBarService.show();
        this.dados.dataCadastro = null;
        this._contaProfissionalAfiliadoControllerService.selecionarDadosAfiliado(this.dados).subscribe(
            (res) => {
                if (res.sucesso) {
                    this.fuseProgressBarService.hide();
                    this.itens = res.listarDetalheAfiliado;
                    console.log(this.itens);
                    this.informacaoConta = res.dadosAfiliadoEndereco;
                    this.prepararItensParaExibicao();
                } else {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                console.log(err);
            }
        );
    }

    private _colunasBase(): void {
        this.colunas = new Array<ListaColunas>(
            {
                descricao: this._i18n.get('LABELS.PROFISSIONAL'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'nomeFoto',
                tipoDado: TipoDado.INNER_HTML,
                tooltipKey: 'nome',
                position: 2,
            },
            {
                descricao: this._i18n.get('LABELS.CPF'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'cpf',
                tipoDado: TipoDado.CPF,
            },
            {
                descricao: this._i18n.get('LABELS.DADOS_CONSELHO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'dadosConselho',
                tipoDado: TipoDado.INNER_HTML,
                position: 4,
            },
            {
                descricao: this._i18n.get('LABELS.EMAIL'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'email',
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.TELEFONE'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'telefone',
                tipoDado: TipoDado.TELEFONE,
            },
            {
                descricao: this._i18n.get('LABELS.PLANO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'plano',
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.SITUACAO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'situacao',
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.DATA_CADASTRO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'dataCadastro',
                tipoDado: TipoDado.DATA,
            },
            {
                descricao: 'Data da Compra',
                visibilidade: TamanhosFlexLayout.md,
                key: 'dataCompra',
                tipoDado: TipoDado.DATA,
            }
        );
    }

    public fechar(): void {
        this.acaoFechar.emit();
    }

    private prepararItensParaExibicao(): void {
        this.itens.forEach((item) => {
            const nome = item.nome.length > 26 ? item.nome.substr(0, 26 - 3) + '...' : item.nome;

            if (item.caminhoFoto && window.innerWidth > WIDTH_XS) {
                item.nomeFoto =
                    '<span style="display:none;">' + nome + '</span><span><img class="avatar" src="' + item.caminhoFoto + '">' + nome + '</span>';
            } else {
                item.nomeFoto = nome;
            }
        });
    }
}
