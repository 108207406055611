import { Component, OnDestroy, OnInit, ViewEncapsulation, NgZone, ViewChild, AfterViewInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { locale as portugues } from 'app/_i18n/pt-br';
import { CadastroConta } from 'app/_models/dominio/cadastro-conta';
import { MatVerticalStepper } from '@angular/material/stepper';
import { AuthenticationService, ToastService, DialogService } from 'app/_services';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialAuthService, FacebookLoginProvider } from 'angularx-social-login';
import { first, takeUntil } from 'rxjs/operators';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { TraducaoService } from 'app/_services/traducao.service';
import { setarValidacaoForm } from 'app/_functions/setar-validacao-form';
import { setNavigationMenu } from 'app/navigation/navigation';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { mascaraTelefone } from 'app/_functions/mascara-telefone';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';
import { MASCARA_CPF, MASCARA_CNPJ } from 'app/_constants/mascaras';
import { cnpjEhValido, cpfEhValido } from 'app/_helpers';
import { ContaColaboradorControllerService } from 'app/_services/controllers/conta-colaborador-controller.service';
import { TermoUsoComponent } from './termo-uso/termo-uso.component';
import { Overlay } from '@angular/cdk/overlay';
import { DomainService } from 'app/_services/domain.service';
import { Estado } from 'app/_models/domain/estado';
import { Cidade } from 'app/_models/domain/cidade';
import { MeuConsultorioControllerService } from 'app/_services/controllers/meu-consultorio-controller.service';
import { RdStationTag } from 'app/_models/enums/rd-station-tag.enum';

@Component({
    selector: 'registrar',
    templateUrl: './registrar.component.html',
    styleUrls: ['./registrar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [fuseAnimations],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ],
})
export class RegistrarComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('stepper', { static: true })
    _stepper: MatVerticalStepper;
    emailForm: FormGroup;
    pessoalForm: FormGroup;
    cadastro: CadastroConta;
    hide: boolean = true;
    user: Observable<firebase.User>;
    private _provider = new firebase.auth.GoogleAuthProvider();
    private _formValid: boolean = false;
    private _usuario: any;
    private _unsubscribeAll: Subject<any>;
    public codigoAtivacao: string;
    public mascaraTelefone: string;
    public mascaraCpfCnpj: string;
    public error: string = '';
    public isLinear: boolean = true;
    public validaLetra: boolean;
    public validaNum: boolean;
    public validarEmail: boolean;
    public estados: Estado[];
    public cidades: Cidade[];

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _dialogService: DialogService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _authServiceFace: SocialAuthService,
        public fuseProgressBarService: FuseProgressBarService,
        private _toastService: ToastService,
        private _i18n: TraducaoService,
        private _overlay: Overlay,
        public authService: AuthenticationService,
        public afAuth: AngularFireAuth,
        public ngZone: NgZone,
        public angularFireAuth: AngularFireAuth,
        private _fuseNavigationService: FuseNavigationService,
        private _contaColaboradorControllerService: ContaColaboradorControllerService,
        private _domainService: DomainService,
        private _meuConsultorioControllerService: MeuConsultorioControllerService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
        // Cadastro com api
        this.afAuth.authState.subscribe((user) => {
            this.user = angularFireAuth.authState;
        });

        this._unsubscribeAll = new Subject();

        this._fuseTranslationLoaderService.loadTranslations(portugues);
        this.cadastro = new CadastroConta();
    }

    get f(): {
        [key: string]: AbstractControl;
    } {
        return this.pessoalForm.controls;
    }

    ngOnInit(): void {
        this.listarEstados();
        this.formValidation();
        this.onChangeTelefone();

        this.pessoalForm.get('radioCpfCnpj').valueChanges.subscribe((valor) => {
            this.setMascaraCpfCnpj(valor);
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.processarUsuarioGoogle();
            this.processarUsuarioFacebook();
        }, 750);

        this.codigoAtivacao = this._route.snapshot.queryParams['codigo'] || null;
    }

    public setValidacaoCpfCnpj(): void {
        const cpfCnpj = this.pessoalForm.get('cpfcnpj');

        if (!cpfCnpj) {
            return;
        }

        cpfCnpj.clearValidators();

        if (cpfCnpj.value === null || cpfCnpj.value.length <= 11) {
            cpfCnpj.setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(11), cpfEhValido]);
        } else {
            cpfCnpj.setValidators([Validators.required, Validators.minLength(14), Validators.maxLength(14), cnpjEhValido]);
        }

        cpfCnpj.updateValueAndValidity();
    }

    public setMascaraCpfCnpj(valor: string): void {
        if (this.isPessoaJuridica()) {
            this.mascaraCpfCnpj = MASCARA_CNPJ;
            return;
        }

        if (valor === 'cpf') {
            this.mascaraCpfCnpj = MASCARA_CPF;
        } else {
            this.mascaraCpfCnpj = MASCARA_CNPJ;
        }
    }

    public isPessoaJuridica(): boolean {
        return this.pessoalForm.get('radioCpfCnpj').value === 'cnpj';
    }

    /**
     * Carrega endereço de acordo com cep.
     */
    public carregarEndereco(): void {
        const cep = this.pessoalForm.get('cep').value;

        if (cep == null || cep.length !== 8) {
            return;
        }

        this.fuseProgressBarService.show();
        this._domainService
            .enderecoPorCep(this.pessoalForm.get('cep').value)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (endereco) => {
                    this.pessoalForm.get('logradouro').setValue(endereco.Logradouro);
                    this.pessoalForm.get('bairro').setValue(endereco.Bairro);
                    this.pessoalForm.get('complemento').setValue(endereco.Complemento);
                    this.listarCidadesPorCodIbge(endereco);
                    this.fuseProgressBarService.hide();
                },
                (err) => {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.CEP_NAO_LOCALIZADO'));
                    console.log(err);
                }
            );
    }

    /**
     * Listar todos os estados no formulário.
     */
    public listarEstados(): void {
        this._domainService
            .listarEstadosCompleto()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (estados) => {
                    this.estados = estados;

                    const uf = this.pessoalForm.get('uf');
                    if (uf.value === '' || uf.value === null) {
                        return;
                    }

                    this.listarCidadesPorUf(uf.value);
                },
                (err) => {
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                    console.log(err);
                }
            );
    }

    /**
     * Lista cidade e estado com seus respectivos ids do banco pelo cód ibge fornecido do cep.
     */
    public listarCidadesPorCodIbge(endereco): void {
        this.fuseProgressBarService.show();
        this._domainService
            .listarCidadeEstadoPorCodIbge(endereco.Ibge)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (cidadeEstado) => {
                    this.fuseProgressBarService.hide();
                    this.pessoalForm.get('uf').setValue(cidadeEstado.estadoId);
                    this.listarCidadesPorUf(cidadeEstado.estadoId);
                    this.pessoalForm.get('cidade').setValue(cidadeEstado.cidadeId);
                },
                (err) => {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                    console.log(err);
                }
            );
    }

    /* Lista cidades de acordo com uf e retorna a uf.  */
    public listarCidadesPorUf(uf: number): void {
        this._domainService
            .listarCidadesPorId(uf)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (cidades) => {
                    this.cidades = cidades;
                },
                (err) => {
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                    console.log(err);
                }
            );
    }

    private onChangeTelefone(): void {
        this.pessoalForm.get('telefone').valueChanges.subscribe((val) => {
            this.mascaraTelefone = mascaraTelefone(val);
        });
    }

    private formValidation(): void {
        this.emailForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]],
        });

        this.pessoalForm = this._formBuilder.group({
            senha: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$')]],
            nome: ['', [Validators.required]],
            telefone: ['', [Validators.required, Validators.minLength(10)]],
            cpfcnpj: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
            radioCpfCnpj: ['cpf'],
            complemento: [''],
            uf: ['', [Validators.required]],
            cep: ['', [Validators.required]],
            logradouro: ['', [Validators.required]],
            numero: ['', [Validators.required]],
            bairro: ['', [Validators.required]],
            cidade: ['', [Validators.required]],
        });
    }

    public limparValidacaoCpfCnpj(): void {
        this.pessoalForm.get('cpfcnpj').clearValidators();
        this.pessoalForm.get('cpfcnpj').setValidators([Validators.maxLength(14)]);
        this.pessoalForm.get('cpfcnpj').updateValueAndValidity();
    }

    public processarUsuarioGoogle(): void {
        const idGoogle = this._route.snapshot.queryParams['idGoogle'] || null;
        const stringUserGoogle = this._route.snapshot.queryParams['userGoogle'] || null;
        let userGoogle: any = null;

        if (stringUserGoogle !== null) {
            userGoogle = JSON.parse(atob(stringUserGoogle));
        }

        if (idGoogle !== null && userGoogle !== null) {
            this.emailForm.get('email').setValue(userGoogle.email.toLowerCase());
            this.pessoalForm.get('nome').setValue(userGoogle.displayName);
            this._usuario = {};
            this._usuario.CaminhoFoto = userGoogle.photoURL;
            this._usuario.contaGoogleId = idGoogle;
            this._usuario.TipoCadastro = 2;

            if (userGoogle.phoneNumber) {
                this._usuario.Telefone = userGoogle.phoneNumber;
            }

            this.setMascaraCpfCnpj('cpf');
            this._stepper.next();
        }
    }

    public processarUsuarioFacebook(): void {
        const idFacebook = this._route.snapshot.queryParams['idFacebook'] || null;
        const stringUserFacebook = this._route.snapshot.queryParams['userFacebook'] || null;
        let userFacebook: any = null;

        if (stringUserFacebook !== null) {
            userFacebook = JSON.parse(atob(stringUserFacebook));
        }

        if (idFacebook !== null && userFacebook !== null) {
            this.emailForm.get('email').setValue(userFacebook.email.toLowerCase());
            this.pessoalForm.get('nome').setValue(userFacebook.name);
            this.cadastro.contaFacebookId = idFacebook;
            this.cadastro.caminhoFoto = userFacebook.photoUrl;
            this.cadastro.tipoCadastro = 3;

            this.setMascaraCpfCnpj('cpf');
            this._stepper.next();
        }
    }

    public google(): void {
        for (const key in this.emailForm.controls) {
            if (this.emailForm.controls.hasOwnProperty(key)) {
                this.emailForm.get(key).clearValidators();
                this.emailForm.get(key).updateValueAndValidity();
            }
        }

        firebase
            .auth()
            .signInWithPopup(this._provider)
            .then((result) => {
                const user = result.user;
                this.emailForm.get('email').setValue(user.email.toLowerCase());
                this.pessoalForm.get('nome').setValue(user.displayName);
                this.cadastro.caminhoFoto = user.photoURL;
                this._usuario = result.additionalUserInfo.profile;
                this.cadastro.contaGoogleId = this._usuario.id;
                this.cadastro.tipoCadastro = 2;
                if (user.phoneNumber) {
                    this.cadastro.telefone = user.phoneNumber;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    public anterior(stepper: MatVerticalStepper): void {
        stepper.previous();
    }

    public facebook(): void {
        for (const key in this.emailForm.controls) {
            if (this.emailForm.controls.hasOwnProperty(key)) {
                this.emailForm.get(key).clearValidators();
                this.emailForm.get(key).updateValueAndValidity();
            }
        }

        this._authServiceFace.signIn(FacebookLoginProvider.PROVIDER_ID).then((result) => {
            this.emailForm.get('email').setValue(result.email.toLowerCase());
            this.pessoalForm.get('nome').setValue(result.name);
            this.cadastro.contaFacebookId = result.id;
            this.cadastro.caminhoFoto = result.photoUrl;
            this.cadastro.tipoCadastro = 3;
        });
    }

    public emailExistente(stepper: MatVerticalStepper): void {
        this.fuseProgressBarService.show();
        this.authService.emailValido(this.emailForm.get('email').value).subscribe(
            (existe) => {
                if (!existe) {
                    stepper.next();
                } else {
                    this.emailForm.get('email').setErrors({
                        existente: true,
                    });
                }

                this.fuseProgressBarService.hide();
            },
            (erro) => {
                console.log(erro);
                this.fuseProgressBarService.hide();
            }
        );
    }

    public validaSenha(): void {
        const senha: string = this.pessoalForm.get('senha').value;
        senha.match('[a-zA-Z]') ? (this.validaLetra = true) : (this.validaLetra = false);
        senha.match('[0-9]') ? (this.validaNum = true) : (this.validaNum = false);

        if (this.validaLetra && this.validaNum && this.pessoalForm.valid) {
            this._formValid = true;
        } else {
            this._formValid = false;
        }
    }

    public validaEmail(): void {
        const email: string = this.emailForm.get('email').value;
        email.match('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}') ? (this.validarEmail = true) : (this.validarEmail = false);
    }

    public dadosProfissionais(stepper: MatVerticalStepper): void {
        stepper.next();
    }

    /*Função novo afiliado */
    private incluirContaAfiliado(usuario: CadastroConta): void {
        this.fuseProgressBarService.show();
        this._contaColaboradorControllerService.incluirContaColaborador(usuario).subscribe(
            (res) => {
                if (res.sucesso) {
                    this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.SUCESSOCADASTRO'));
                    this.fuseProgressBarService.hide();
                    this.logar(usuario.email, atob(usuario.senha), RoleUsuario.ADMINISTRADOR_AFILIADO);
                } else {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                console.log(err);
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
            }
        );
    }

    private retornarUsuarioDoForm(): CadastroConta {
        const usuario: CadastroConta = Object.assign(
            {
                cpf: this.pessoalForm.get('cpfcnpj').value,
                email: this.emailForm.get('email').value.toLowerCase(),
            },
            this.pessoalForm.value,
            this.cadastro
        );

        usuario.senha = btoa(this.pessoalForm.get('senha').value);
        return usuario;
    }

    /* Função botão de cadastro */
    public realizarCadastro(): void {
        if (!this.pessoalForm.valid) {
            this.setValidacaoCpfCnpj();
            setarValidacaoForm(this.pessoalForm);
            return;
        }

        this.termoContrato();
    }

    private logar(email: string, senha: string, roleUsuario: RoleUsuario): void {
        this.fuseProgressBarService.show();
        const tipoLogin = 1;

        this.authService
            .login(email, senha, tipoLogin)
            .pipe(first())
            .subscribe(
                () => {
                    this._router.navigate(['/inicio']);
                    setNavigationMenu(this.authService, this._fuseNavigationService);
                    this._fuseTranslationLoaderService.loadTranslations(portugues);
                    this.incluirRegistroRdStation();
                    this.fuseProgressBarService.hide();
                },
                (error) => {
                    this.error = error;
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.NAOCONSEGUELOGAR'));
                }
            );
    }

    private termoContrato(): void {
        this._dialogService
            .dialogGenerico(TermoUsoComponent, {
                scrollStrategy: this._overlay.scrollStrategies.noop(),
                autoFocus: false,
            })
            .subscribe((aceito: boolean) => {
                if (aceito) {
                    const usuario = this.retornarUsuarioDoForm();
                    this.incluirContaAfiliado(usuario);
                }
            });
    }

    private incluirRegistroRdStation(): void {
        this._meuConsultorioControllerService.incluirRegistroRdStation(RdStationTag.PARCEIRO_AFILIADO, RoleUsuario.AFILIADO).subscribe(
            (res) => {},
            (err) => {
                console.log(`Erro cadastro RD Station: ${err}`);
            }
        );
    }

    /* On destroy */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
