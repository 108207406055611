import { ContaProfissionalControllerService } from 'app/_services/controllers/conta-profissional-controller.service';
import { Component, OnInit } from '@angular/core';
import { TraducaoService, ToastService, AuthenticationService, DialogService } from 'app/_services';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { IndicacoesConversoesBaseComponent } from 'app/main/inicio/indicacoes-conversoes/indicacoes-conversoes-base.component';
import { Conversoes } from 'app/_models/conta-profissional/conversoes';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';
import { ConversoesAfiliadosService } from './conversoes-afiliado.service';
import { ContaColaboradorControllerService } from 'app/_services/controllers/conta-colaborador-controller.service';
import { ConversoesAdministradorService } from 'app/main/inicio/indicacoes-conversoes/conversoes/conversoes-administrador.service';
import { ExportarPdfService } from 'app/_services/exportacao/exportar-pdf.service';
import { ExportaXLSXService } from 'app/_services/exportacao/exporta-xlsx.service';

@Component({
    selector: 'conversoes',
    templateUrl: './conversoes.component.html',
})
export class ConversoesComponent extends IndicacoesConversoesBaseComponent<Conversoes> implements OnInit {
    public exibirBtnResgate: boolean = false;
    private _service: ConversoesAdministradorService | ConversoesAfiliadosService;

    constructor(
        protected fuseProgressBarService: FuseProgressBarService,
        protected _i18n: TraducaoService,
        protected _contaProfissionalService: ContaProfissionalControllerService,
        protected _toastService: ToastService,
        protected _authenticationService: AuthenticationService,
        protected _currencyPipe: CurrencyPipe,
        private _dialogService: DialogService,
        private _contaColaboradorService: ContaColaboradorControllerService,
        private _exportarPdfService: ExportarPdfService,
        private _exportaXLSXService: ExportaXLSXService,
        private datePipe: DatePipe
    ) {
        super(fuseProgressBarService, _i18n, _contaProfissionalService, _toastService, _authenticationService);
    }

    ngOnInit(): void {
        switch (this.usuario.perfil) {
            case RoleUsuario.ADMINISTRADOR_AFILIADO:
                this.listarDadosAdmin();
                break;

            case RoleUsuario.AFILIADO:
                this.exibirBtnResgate = true;
                this.listarDadosAfiliado();
                break;
        }
    }

    protected listarDadosAdmin(): void {
        this.fuseProgressBarService.show();

        this._contaProfissionalService.listarConversoesPorAdministrador().subscribe(
            (res) => {
                if (res.sucesso) {
                    this._service = new ConversoesAdministradorService(
                        res.indicadoresDTO,
                        res.conversoesDTO,
                        this._i18n,
                        this._currencyPipe,
                        this._exportarPdfService,
                        this._exportaXLSXService,
                        this.datePipe
                    );
                    this.setValues<ConversoesAdministradorService>(this._service, res.conversoesDTO);

                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                } else {
                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                console.log(err);
            }
        );
    }

    protected listarDadosAfiliado(): void {
        this.fuseProgressBarService.show();
        const roleUsuario: RoleUsuario = this._authenticationService.currentUserValue.customProperties.RoleMeuConsultorio;

        this._contaProfissionalService.listarConversoesPorAfiliado(roleUsuario).subscribe(
            (res) => {
                if (res.sucesso) {
                    this._service = new ConversoesAfiliadosService(
                        res.indicadoresDTO,
                        res.conversoesDTO,
                        this._i18n,
                        this._currencyPipe,
                        this._exportarPdfService,
                        this._exportaXLSXService,
                        this.datePipe
                    );
                    this.setValues<ConversoesAfiliadosService>(this._service, res.conversoesDTO);

                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                } else {
                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                console.log(err);
            }
        );
    }

    public exportarParaPdf(itens: Array<Conversoes>): void {
        this._service.exportarParaPdf(itens);
    }

    public exportarParaXLSX(itens: Array<Conversoes>): void {
        this._service.exportarParaExcel(itens);
    }

    public solicitarResgate(): void {
        const roleUsuario: RoleUsuario = this._authenticationService.currentUserValue.customProperties.RoleMeuConsultorio;

        this.fuseProgressBarService.show();
        this._contaColaboradorService.solicitarResgate(roleUsuario).subscribe(
            (res) => {
                if (res.sucesso) {
                    this.carregamentoInicial = true;
                    this._dialogService.dialogInformacao(this._i18n.get('LABELS.ATENCAO'), this._i18n.get('MENSAGENS.SOLICITAR_RESGATE'));
                    this.listarDadosAfiliado();
                    this.fuseProgressBarService.hide();
                } else {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                console.log(err);
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
            }
        );
    }
}
