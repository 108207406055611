import { Component, OnInit, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as portugues } from 'app/_i18n/pt-br';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlterarFotoComponent } from 'app/main/componentes/alterar-foto/alterar-foto.component';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ToastService, DialogService, TraducaoService, AuthenticationService } from 'app/_services';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cpfEhValido, cnpjEhValido } from 'app/_helpers';
import { Foto } from 'app/_models/alterar-foto/alterar-foto.model';
import { Estado } from 'app/_models/domain/estado';
import { Cidade } from 'app/_models/domain/cidade';
import { ModoSalvar } from 'app/_models/enums/modo-salvar';
import { User, ConselhoDTO } from 'app/_models';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MY_FORMATS } from 'app/_constants/date-format';
import { ContaColaboradorControllerService } from 'app/_services/controllers/conta-colaborador-controller.service';
import { mascaraTelefone } from 'app/_functions/mascara-telefone';
import { markFormGroupTouched } from 'app/_functions';
import { validarDigitoData } from 'app/_functions/validar-digito-data';
import { ContaProfissionalControllerService } from 'app/_services/controllers/conta-profissional-controller.service';
import { DomainService } from 'app/_services/domain.service';
import { AlterarPerfilService } from 'app/_services/controllers/alterar-perfil.service';
import { MASCARA_CNPJ, MASCARA_CPF } from 'app/_constants/mascaras';
import { MatVerticalStepper } from '@angular/material/stepper';
import { PerfilAfiliadoDto } from 'app/_models/alterar-perfil/perfil-afiliado-dto';
import { Router } from '@angular/router';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

@Component({
    selector: 'app-alterar-perfil',
    templateUrl: './alterar-perfil.component.html',
    styleUrls: ['./alterar-perfil.component.scss'],
    animations: fuseAnimations,
    providers: [
        DatePipe,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    ],
})
export class AlterarPerfilComponent implements OnInit, OnDestroy {
    private _tamanhoModalEditarFoto: string;
    public form: FormGroup;
    public estados: Estado[];
    public cidades: Cidade[];
    public conselhos: ConselhoDTO[];
    public especialidades: string[];
    public currentUser: User;
    public afiliado: PerfilAfiliadoDto;
    private _unsubscribeAll: Subject<any>;
    public mascaraTelefone: string;
    public dataAtual: Date;
    public mascaraCpfCnpj: string;
    public validarEmail: boolean;
    public roleUsuarioMeuConsultorio: RoleUsuario;

    /**
     * Constructor
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _dialogService: DialogService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _alterarPerfilService: AlterarPerfilService,
        private _contaProfissionalControllerService: ContaProfissionalControllerService,
        private _toastService: ToastService,
        private _domainService: DomainService,
        private _authenticationService: AuthenticationService,
        private _i18n: TraducaoService,
        private _router: Router,
        public fuseProgressBarService: FuseProgressBarService,
        private _contaColaboradorControllerService: ContaColaboradorControllerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AlterarPerfilComponent>
    ) {
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(portugues);
    }

    ngOnInit(): void {
        this.afiliado = this.data.afiliado;
        this.roleUsuarioMeuConsultorio = this.data.roleUsuarioMeuConsultorio;
        this.currentUser = this.data.currentUser;

        this.listarEstados();
        this.setFormValidade();

        this.mascaraTelefone = mascaraTelefone(this.afiliado.telefone);
        this.mascaraCpfCnpj = this.isCnpj() ? MASCARA_CNPJ : MASCARA_CPF;

        this.onChangeRadioCpfCnpj();
        this.onChangeTelefone();

        this.verificaAlteracoesFoto();
        this.desabilitarCampos();
    }

    private get usuario(): User {
        return this._authenticationService.currentUserValue;
    }

    private desabilitarCampos(): void {
        if (this.usuario.perfil !== RoleUsuario.ADMINISTRADOR_AFILIADO) {
            this.form.get('email').disable();
        }

        if (this.afiliado.cpf != null) {
            this.form.get('cpfcnpj').disable();
            this.form.get('radioCpfCnpj').disable();
        }
    }

    public onChangeRadioCpfCnpj(): void {
        this.form.get('radioCpfCnpj').valueChanges.subscribe((valor) => {
            this.setMascaraCpfCnpj(valor);
        });
    }

    public isPessoaJuridica(): boolean {
        return this.form.get('radioCpfCnpj').value === 'cnpj';
    }

    private isCnpj(): boolean {
        return this.afiliado.cpf != null && this.afiliado.cpf.length > 11;
    }

    public setMascaraCpfCnpj(valor: string): void {
        if (valor === 'cpf') {
            this.mascaraCpfCnpj = MASCARA_CPF;
        } else {
            this.mascaraCpfCnpj = MASCARA_CNPJ;
        }
    }

    private setFormValidade(): void {
        this.form = this._formBuilder.group({
            radioCpfCnpj: this.isCnpj() ? ['cnpj'] : ['cpf'],
            cpfcnpj: [this.afiliado.cpf, [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
            nome: [this.afiliado.nome, [Validators.required, Validators.maxLength(100)]],
            telefone: [this.afiliado.telefone, [Validators.maxLength(14), Validators.pattern(/\d{10,11}/)]],
            email: [this.afiliado.email, [Validators.required, Validators.email, Validators.minLength(10), Validators.maxLength(100)]],
            cep: [this.afiliado.cep, [Validators.required]],
            logradouro: [this.afiliado.logradouro, [Validators.required]],
            numero: [this.afiliado.numero, [Validators.required]],
            complemento: [this.afiliado.complemento],
            bairro: [this.afiliado.bairro, [Validators.required]],
            uf: [this.afiliado.uf, [Validators.required]],
            cidade: [this.afiliado.cidade, [Validators.required]],
            dataNascimento: [this.afiliado.dataNascimento],
            sexo: [this.afiliado.sexo, [Validators.maxLength(10)]],
        });
    }

    private onChangeTelefone(): void {
        this.form.get('telefone').valueChanges.subscribe((val) => {
            this.mascaraTelefone = mascaraTelefone(val);
        });
    }

    /**
     * Abrir modal para alterar foto.
     */
    public alterarFoto(): void {
        const foto: Foto<AlterarPerfilService> = {
            src: this.afiliado.caminhoFoto,
            id: this.afiliado.id,
            service: this._alterarPerfilService,
            titulo: 'Alterar foto',
            modoSalvar: ModoSalvar.Put,
        };

        if (window.innerWidth < 600) {
            this._tamanhoModalEditarFoto = '70%';
        } else if (window.innerWidth >= 600 && window.innerWidth < 690) {
            this._tamanhoModalEditarFoto = '60%';
        } else if (window.innerWidth >= 690 && window.innerWidth < 1279) {
            this._tamanhoModalEditarFoto = '50%';
        } else {
            this._tamanhoModalEditarFoto = '40%';
        }

        this._dialogService.dialogGenerico(AlterarFotoComponent, {
            data: foto,
            disableClose: false,
            maxWidth: '70vh',
            width: this._tamanhoModalEditarFoto,
        });
    }

    private retornarUsuarioDoForm(): PerfilAfiliadoDto {
        const usuario: PerfilAfiliadoDto = Object.assign(
            {
                cpf: this.form.get('cpfcnpj').value,
                email: this.form.get('email').value.toLowerCase(),
            },
            this.form.value
        );
        return usuario;
    }

    public alterarUsuario(): void {
        if (!this.form.valid) {
            markFormGroupTouched(this.form);
            return;
        }

        this.fuseProgressBarService.show();

        if (this.roleUsuarioMeuConsultorio === RoleUsuario.ADMIN) {
            const profissionalAlterado = this.retornarUsuarioDoForm();

            this._contaProfissionalControllerService
                .alterarContaProfissionalComPerfilAfiliado(profissionalAlterado)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (res) => {
                        if (res.sucesso) {
                            this._alterarPerfilService.setAlteracoes(res.perfilAfiliado);
                            this.fuseProgressBarService.hide();
                            this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.DADOSSALVOS'));
                            this._authenticationService.updateAposValidarCadastro();
                            this.dialogRef.close();
                        } else {
                            this.fuseProgressBarService.hide();
                            this._toastService.mensagemError(res.mensagemErro);
                            console.log(res.mensagemErro);
                        }
                    },
                    (err) => {
                        this.fuseProgressBarService.hide();
                        this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                        console.log(err);
                    }
                );
        } else {
            const colaboradorAlterado = this.retornarUsuarioDoForm();
            this._contaColaboradorControllerService
                .alterarContaColaboradorComPerfilAfiliado(colaboradorAlterado)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (res) => {
                        if (res.sucesso) {
                            this._alterarPerfilService.setAlteracoes(res.perfilAfiliado);
                            this.fuseProgressBarService.hide();
                            this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.DADOSSALVOS'));
                            this._authenticationService.updateAposValidarCadastro();
                            this.dialogRef.close();
                        } else {
                            this.fuseProgressBarService.hide();
                            this._toastService.mensagemError(res.mensagemErro);
                            console.log(res.mensagemErro);
                        }
                    },
                    (err) => {
                        this.fuseProgressBarService.hide();
                        this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                        console.log(err);
                    }
                );
        }
    }

    /**
     * Listar todos os estados no formulário.
     */
    private listarEstados(): void {
        this._domainService
            .listarEstadosCompleto()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (estados) => {
                    this.estados = estados;
                    if (this.afiliado.uf) {
                        this.listarCidadesPorUf(this.afiliado.uf);
                    }
                },
                (err) => {
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                    console.log(err);
                }
            );
    }

    /**
     * Lista cidades de acordo com uf e retorna a uf.
     */
    public listarCidadesPorUf(uf: number): void {
        this._domainService
            .listarCidadesPorId(uf)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (cidades) => {
                    this.cidades = cidades;
                },
                (err) => {
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                    console.log(err);
                }
            );
    }

    /**
     * Carrega endereço de acordo com cep.
     */
    public carregarEndereco(): void {
        const cep = this.form.get('cep').value;
        if (cep == null || cep.length !== 8) {
            return;
        }
        this.fuseProgressBarService.show();
        this._domainService
            .enderecoPorCep(this.form.get('cep').value)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (endereco) => {
                    this.form.get('logradouro').setValue(endereco.Logradouro);
                    this.form.get('bairro').setValue(endereco.Bairro);
                    this.form.get('complemento').setValue(endereco.Complemento);
                    this.listarCidadesPorCodIbge(endereco);
                    this.fuseProgressBarService.hide();
                },
                (err) => {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.CEP_NAO_LOCALIZADO'));
                    console.log(err);
                }
            );
    }

    /**
     * Lista cidade e estado com seus respectivos ids do banco pelo cód ibge fornecido do cep.
     */
    public listarCidadesPorCodIbge(endereco): void {
        this.fuseProgressBarService.show();
        this._domainService
            .listarCidadeEstadoPorCodIbge(endereco.Ibge)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (cidadeEstado) => {
                    this.fuseProgressBarService.hide();
                    this.form.get('uf').setValue(cidadeEstado.estadoId);
                    this.listarCidadesPorUf(cidadeEstado.estadoId);
                    this.form.get('cidade').setValue(cidadeEstado.cidadeId);
                },
                (err) => {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                    console.log(err);
                }
            );
    }

    public emailExistente(stepper: MatVerticalStepper): void {
        this.fuseProgressBarService.show();
        this._authenticationService.emailValido(this.form.get('email').value).subscribe(
            (existe) => {
                if (!existe) {
                    stepper.next();
                } else {
                    this.form.get('email').setErrors({
                        existente: true,
                    });
                }

                this.fuseProgressBarService.hide();
            },
            (erro) => {
                console.log(erro);
                this.fuseProgressBarService.hide();
            }
        );
    }

    public validaEmail(): void {
        const email: string = this.form.get('email').value;
        email.match('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}') ? (this.validarEmail = true) : (this.validarEmail = false);
    }

    /**
     * Valida em tempo real se o cpf é existente.
     */
    public validaCpf(): void {
        if (this.form.get('cpf').value) {
            this.fuseProgressBarService.show();
            this.cpfValido(this.form.get('cpf').value)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((bool) => {
                    if (bool) {
                        this.form.get('cpf').setErrors({
                            existente: true,
                        });
                    }
                    this.fuseProgressBarService.hide();
                });
        }
    }

    /**
     * Verifica em tempo real alterações de foto.
     */
    public verificaAlteracoesFoto(): void {
        this._alterarPerfilService.foto.pipe(takeUntil(this._unsubscribeAll)).subscribe((url) => {
            this.afiliado.caminhoFoto = url;
        });
    }

    private cpfValido(cpf: string): Observable<boolean> {
        return this._contaProfissionalControllerService.cpfEhValido(cpf);
    }

    public onKeyPressData(event: KeyboardEvent): void {
        if (!validarDigitoData(event.key)) {
            event.preventDefault();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public limparValidacaoCpfCnpj(): void {
        this.form.get('cpfcnpj').clearValidators();
        this.form.get('cpfcnpj').setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(14)]);
        this.form.get('cpfcnpj').updateValueAndValidity();
    }

    public setValidacaoCpfCnpj(): void {
        const cpfCnpj = this.form.get('cpfcnpj');

        if (!cpfCnpj) {
            return;
        }

        cpfCnpj.clearValidators();

        if (this.isPessoaJuridica()) {
            cpfCnpj.setValidators([Validators.required, Validators.minLength(14), Validators.maxLength(14), cnpjEhValido]);
        } else {
            cpfCnpj.setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(11), cpfEhValido]);
        }

        cpfCnpj.updateValueAndValidity();
    }

    public ocultarOpcaoCpfCnpj(): boolean {
        return (
            !!this.roleUsuarioMeuConsultorio &&
            (this.roleUsuarioMeuConsultorio === RoleUsuario.ADMIN || this.roleUsuarioMeuConsultorio === RoleUsuario.SECRETARIA)
        );
    }

    public fecharModalAlterarPerfil(): void {
        const obrigatorioCompletarCadastro: boolean = this.usuario.customProperties.ExibirConfirmacaoCadastro;

        if (!obrigatorioCompletarCadastro) {
            this.dialogRef.close();
            return;
        }

        this._dialogService.dialogPergunta(
            this._i18n.get('MENSAGENS.DADOS_SAO_OBRIGATORIOS_TITULO'),
            this._i18n.get('MENSAGENS.DADOS_SAO_OBRIGATORIOS_MENSAGEM'),
            null,
            () => {
                this.dialogRef.close();
                this._router.navigate(['/login']);
                this._authenticationService.logout();
            },
            () => {}
        );
    }
}
