import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DadosPagamentoDto } from 'app/_models/conta-profissional-afiliado/dados-pagamento-dto';
import { ContaProfissionalAfiliadoControllerService } from 'app/_services/controllers/conta-profissional-afiliado-controller.service';
import { ToastService } from 'app/_services/toast.service';
import { TraducaoService } from 'app/_services/traducao.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ListaColunas } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DATA_MINIMA } from 'app/_constants/data-minima';
import { validarDigitoData } from 'app/_functions/validar-digito-data';
import { DatePipe, formatCurrency } from '@angular/common';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'app/_constants';
import { ModoSalvar } from 'app/_models/enums/modo-salvar';
import { AlterarDadosPagamentoDto } from 'app/_models/conta-profissional-afiliado/alterar-dados-pagamento-dto';
import { setarValidacaoForm } from '../../../_functions/setar-validacao-form';
import { DadosPagamentoAfiliadosControllerService } from 'app/_services/controllers/dados-pagamento-afiliados.service';
import { DadosPagamento } from 'app/_models/dados-pagamento/dados-pagamento';
import { MASCARA_CPF } from 'app/_constants/mascaras';

@Component({
    selector: 'alterar-pagamento',
    templateUrl: './alterar-pagamento.component.html',
    styleUrls: ['./alterar-pagamento.component.scss'],
    providers: [
        DatePipe,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    ],
})
export class AlterarPagamentoComponent implements OnInit {
    public dadosPagamento: DadosPagamentoDto;
    public dadosParaPagamento: DadosPagamento;
    public displayedColumns: Array<string>;
    public colunas: Array<ListaColunas>;
    public form: FormGroup;
    public dataMinima: Date = DATA_MINIMA;
    public modoSalvar: ModoSalvar;
    private _alterarDadosPagamentoDto: AlterarDadosPagamentoDto;
    public mascaraCpfCnpj: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DadosPagamentoDto,
        public dialogRef: MatDialogRef<AlterarPagamentoComponent>,
        private _contaProfissionalAfiliadoControllerService: ContaProfissionalAfiliadoControllerService,
        private _toastService: ToastService,
        private _i18n: TraducaoService,
        private _formBuilder: FormBuilder,
        public fuseProgressBarService: FuseProgressBarService,
        private _dadosPagamentoAfiliadosControllerService: DadosPagamentoAfiliadosControllerService
    ) {
        this.dadosPagamento = this.data;
        this.colunas = new Array<ListaColunas>();
        this.modoSalvar = !!this.dadosPagamento.notaFiscal && !!this.dadosPagamento.dataPagamento ? ModoSalvar.Put : ModoSalvar.Post;
    }

    ngOnInit(): void {
        this.formValidation();
        this.setarDadosEdicao();
        this.setColunas();
        this.buscarDadosParaPagamento();
        this.mascaraCpfCnpj = MASCARA_CPF;
    }

    private formValidation(): void {
        this.form = this._formBuilder.group({
            notaFiscal: ['', [Validators.required, Validators.maxLength(25)]],
            observacao: [''],
            formaPagamentoId: [5, [Validators.required]],
            dataPagamento: [new Date(), Validators.required],
        });
    }

    private setarDadosEdicao(): void {
        if (this.modoSalvar === ModoSalvar.Put) {
            this.form.patchValue(this.dadosPagamento);
        }
    }

    private setColunas(): void {
        this.colunas = new Array<ListaColunas>(
            {
                descricao: this._i18n.get('LABELS.DATA_COMPRA'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'dataCompra',
                tipoDado: TipoDado.DATA,
            },
            {
                descricao: this._i18n.get('LABELS.PROFISSIONAL'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'nome',
                maxLengthKey: 17,
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.CPF'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'cpf',
                tipoDado: TipoDado.CPF,
            },
            {
                descricao: this._i18n.get('LABELS.VALOR_PLANO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'valorPlano',
                tipoDado: TipoDado.MONETARIO,
            },
            {
                descricao: this._i18n.get('LABELS.VALOR_BONUS'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'valorBonus',
                tipoDado: TipoDado.MONETARIO,
            }
        );
    }

    public onKeyPressDataPagamento(event: KeyboardEvent): void {
        if (!validarDigitoData(event.key)) {
            event.preventDefault();
        }
    }

    public retornarNomeAfiliado(): string {
        const nome = this.dadosPagamento.afiliadoNome;
        return nome.length > 17 ? nome.substr(0, 17 - 3) + '..' : nome;
    }

    public alterarPagamento(): void {
        if (!this.form.valid) {
            setarValidacaoForm(this.form);
            return;
        }

        this.fuseProgressBarService.show();

        this._alterarDadosPagamentoDto = Object.assign(
            {
                contaProfissionalAfiliadosIds: this.dadosPagamento.conversoes.map((conversao) => conversao.contaProfissionalAfiliadoId),
                afiliadoId: this.dadosPagamento.afiliadoId,
            },
            this.form.value
        );

        this._contaProfissionalAfiliadoControllerService.alterarPagamento(this._alterarDadosPagamentoDto).subscribe(
            (res) => {
                if (res.sucesso) {
                    this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.DADOSSALVOS'));
                    this.dialogRef.close(true);
                } else {
                    this._toastService.mensagemError(res.mensagemErro);
                }

                this.fuseProgressBarService.hide();
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this.dialogRef.close(false);
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                console.log(err);
            }
        );
    }

    public formatarTotalBonus(): string {
        return formatCurrency(this.dadosPagamento.totalBonus, 'pt-BR', 'R$');
    }

    public ehCpf(): boolean {
        return this.dadosPagamento.afiliadoCpf.length === 11;
    }

    public buscarDadosParaPagamento(): void {
        this._dadosPagamentoAfiliadosControllerService.selecionarPorAdmin(this.data.afiliadoId, this.data.roleUsuario).subscribe(
            (res) => {
                this.dadosParaPagamento = res.dados;
                this.fuseProgressBarService.hide();
            },
            (err) => {
                this.fuseProgressBarService.hide();
                console.log(err);
            }
        );
    }
}
