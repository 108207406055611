import { ContaProfissionalControllerService } from 'app/_services/controllers/conta-profissional-controller.service';
import { Component, OnInit } from '@angular/core';
import { TraducaoService, ToastService, AuthenticationService } from 'app/_services';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { IndicacoesConversoesBaseComponent } from 'app/main/inicio/indicacoes-conversoes/indicacoes-conversoes-base.component';
import { Indicacoes } from 'app/_models/conta-profissional/indicacoes';
import { IndicacoesAdministradorService } from './indicacoes-administrador.service';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';
import { IndicacoesAfiliadoService } from 'app/main/inicio/indicacoes-conversoes/indicacoes/indicacoes-afiliado.service';
import { ExportarPdfService } from 'app/_services/exportacao/exportar-pdf.service';
import { ExportaXLSXService } from 'app/_services/exportacao/exporta-xlsx.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'indicacoes',
    templateUrl: './indicacoes.component.html',
})
export class IndicacoesComponent extends IndicacoesConversoesBaseComponent<Indicacoes> implements OnInit {
    private _service: IndicacoesAdministradorService | IndicacoesAfiliadoService;

    constructor(
        protected fuseProgressBarService: FuseProgressBarService,
        protected _i18n: TraducaoService,
        protected _contaProfissionalService: ContaProfissionalControllerService,
        protected _toastService: ToastService,
        protected _authenticationService: AuthenticationService,
        private _exportarPdfService: ExportarPdfService,
        private _exportaXLSXService: ExportaXLSXService,
        private datePipe: DatePipe
    ) {
        super(fuseProgressBarService, _i18n, _contaProfissionalService, _toastService, _authenticationService);
    }

    ngOnInit(): void {
        switch (this.usuario.perfil) {
            case RoleUsuario.ADMINISTRADOR_AFILIADO:
                this.listarDadosAdmin();
                break;

            case RoleUsuario.AFILIADO:
                this.listarDadosAfiliado();
                break;
        }
    }

    protected listarDadosAdmin(): void {
        this.fuseProgressBarService.show();

        this._contaProfissionalService.listarIndicacoesPorAdministrador().subscribe(
            (res) => {
                if (res.sucesso) {
                    this._service = new IndicacoesAdministradorService(
                        res.meusIndicadoresDTO,
                        res.indicacoesDTO,
                        this._i18n,
                        this._exportarPdfService,
                        this._exportaXLSXService,
                        this.datePipe
                    );
                    this.setValues<IndicacoesAdministradorService>(this._service, res.indicacoesDTO);

                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                } else {
                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                console.log(err);
            }
        );
    }

    protected listarDadosAfiliado(): void {
        this.fuseProgressBarService.show();
        const roleUsuario: RoleUsuario = this._authenticationService.currentUserValue.customProperties.RoleMeuConsultorio;

        this._contaProfissionalService.listarIndicacoesPorAfiliado(roleUsuario).subscribe(
            (res) => {
                if (res.sucesso) {
                    this._service = new IndicacoesAfiliadoService(
                        res.meusIndicadoresDTO,
                        res.indicacoesDTO,
                        this._i18n,
                        this._exportarPdfService,
                        this._exportaXLSXService,
                        this.datePipe
                    );
                    this.setValues<IndicacoesAfiliadoService>(this._service, res.indicacoesDTO);

                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                } else {
                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
                console.log(err);
            }
        );
    }

    public exportarParaPdf(itens: Array<Indicacoes>): void {
        this._service.exportarParaPdf(itens);
    }

    public exportarParaXLSX(itens: Array<Indicacoes>): void {
        this._service.exportarParaExcel(itens);
    }
}
