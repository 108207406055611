import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MASCARA_CPF } from 'app/_constants/mascaras';
import { markFormGroupTouched } from 'app/_functions';
import { cpfEhValido } from 'app/_helpers';
import { AuthenticationService, DialogService, ToastService, TraducaoService } from 'app/_services';
import { DadosPagamento } from 'app/_models/dados-pagamento/dados-pagamento';
import { DadosPagamentoAfiliadosControllerService } from 'app/_services/controllers/dados-pagamento-afiliados.service';
import { ModoSalvar } from 'app/_models';

@Component({
    selector: 'app-dados-pagamento',
    templateUrl: './dados-pagamento.component.html',
    styleUrls: ['./dados-pagamento.component.scss'],
})
export class DadosPagamentoComponent implements OnInit {
    public form: FormGroup;
    public mascaraCpfCnpj: string;

    constructor(
        private _toastService: ToastService,
        private _i18n: TraducaoService,
        private _dialogService: DialogService,
        private _authenticationService: AuthenticationService,
        public fuseProgressBarService: FuseProgressBarService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: DadosPagamento,
        public dialogRef: MatDialogRef<DadosPagamentoComponent>,
        private _dadosPagamentoAfiliadosControllerService: DadosPagamentoAfiliadosControllerService
    ) {}

    ngOnInit() {
        this.form = this._formBuilder.group({
            banco: [this.data.banco, [Validators.maxLength(100)]],
            operacao: [this.data.operacao, [Validators.maxLength(25)]],
            agencia: [this.data.agencia, [Validators.maxLength(25)]],
            conta: [this.data.conta, [Validators.maxLength(25)]],
            pix: [this.data.pix, [Validators.maxLength(250)]],
            nomeCompleto: [this.data.nomeCompleto, [Validators.required, Validators.maxLength(100)]],
            cpfTitular: [this.data.cpfTitular, [Validators.required, Validators.maxLength(15)]],
        });
        this.mascaraCpfCnpj = MASCARA_CPF;
    }

    public setValidacaoCpfCnpj(): void {
        const cpfCnpj = this.form.get('cpfTitular');
        if (!cpfCnpj) {
            return;
        }
        cpfCnpj.clearValidators();
        cpfCnpj.setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(11), cpfEhValido]);
        cpfCnpj.updateValueAndValidity();
    }

    public limparValidacaoCpfCnpj(): void {
        this.form.get('cpfTitular').clearValidators();
        this.form.get('cpfTitular').setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(11)]);
        this.form.get('cpfTitular').updateValueAndValidity();
    }

    private _validarFormulario(): boolean {
        const pix = this.form.get('pix').value as string;
        this._removeErrors();
        if (pix != null) {
            if (this.form.get('cpfTitular').valid && this.form.get('nomeCompleto').valid) {
                return true;
            }
            markFormGroupTouched(this.form);
            return false;
        }
        this.form.get('banco').setValidators([Validators.required]);
        this.form.get('banco').updateValueAndValidity();
        this.form.get('operacao').setValidators([Validators.required]);
        this.form.get('operacao').updateValueAndValidity();
        this.form.get('agencia').setValidators([Validators.required]);
        this.form.get('agencia').updateValueAndValidity();
        this.form.get('conta').setValidators([Validators.required]);
        this.form.get('conta').updateValueAndValidity();
        markFormGroupTouched(this.form);
        return this.form.valid;
    }

    public _removeErrors(): void {
        this.form.get('banco').setErrors(null);
        this.form.get('operacao').setErrors(null);
        this.form.get('agencia').setErrors(null);
        this.form.get('conta').setErrors(null);
    }

    public salvar(): void {
        if (!this._validarFormulario()) {
            return;
        }

        this.fuseProgressBarService.show();
        if (this.data.nomeCompleto != null) {
            this.alterar();
            return;
        }

        let dadosPg = this.form.value as DadosPagamento;
        this._dadosPagamentoAfiliadosControllerService.incluir(dadosPg).subscribe(
            (res) => {
                this.fuseProgressBarService.hide();
                if (res.sucesso) {
                    this._authenticationService.updateAlertaDadosPagamento();
                    this.dialogRef.close();
                    this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.DADOS_PAGAMENTO_SUCESSO'));
                } else {
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                console.log(err);
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
            }
        );
    }

    private alterar(): void {
        let dadosPg = this.form.value as DadosPagamento;
        this._dadosPagamentoAfiliadosControllerService.alterar(dadosPg).subscribe(
            (res) => {
                this.fuseProgressBarService.hide();
                if (res.sucesso) {
                    this.dialogRef.close();
                    this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.DADOS_PAGAMENTO_SUCESSO'));
                } else {
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                console.log(err);
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOSUPORTE'));
            }
        );
    }
}
